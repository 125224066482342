const initialState = {
    creditsData: {
        credit_balance: 0,
        credit_total: 0,
    },
    showSubscriptionModal: false,
    currentPlan: null,
}

export default function stripeReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_STRIPE':
            console.log(action.payload, "action.payload")
            return {
                ...state,
                creditsData: {
                    credit_balance: action.payload?.credit_balance,
                    credit_total: action.payload?.credit_total,
                }
            };
        case 'UPDATE_CURRENT_PLAN':
            return {
                ...state,
                currentPlan: action.payload,
            };
        case 'SHOW_SUBSCRIBE_MODAL':
            return {
                ...state,
                showSubscriptionModal: action.payload,
            };
        default:
            return state;
    }
}