import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'

export const fetchSuppliers = () => {
    return async (dispatch) => {
        try {
            const { data, status } = await authenticatedInstance.get("/supplier/all");
            if (status === 200) {
                // Dispatch the action to update categories in the Redux store
                dispatch({
                    type: 'SET_SUPPLIERS', 
                    payload: data,
                });
            }
        } catch (error) {
            console.log(error, "Suppliers could not be fetched");
            toast.error("Could not fetch Suppliers!");
        }
    };
}