import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { handlePayment } from "./paymentActions";
import { useNavigate } from "react-router-dom";
import { toast } from 'sonner'

const cleanEmail = (email) => {
  const cleanedEmail = email.toLowerCase().trim();
  return cleanedEmail;
}

const cleanPassword = (password) => {
  const cleanedPassword = password.trim();
  return cleanedPassword;
}

export const handleSignUp = async ({ email, password, name, termsAccepted }) => {
  if (!email || !password || !name) {
    toast.error("Please fill all the fields")
    return false;
  }
  if (!termsAccepted) {
    toast.error("Please accept terms and conditions")
    return false;
  }

  if (password.length < 8) {
    toast.error("Password must be atleast 8 characters long")
    return false;
  }

  email = cleanEmail(email);
  password = cleanPassword(password);

  try {
    const { data, status } = await instance.post("/user/signup", {
      email: email,
      password: password,
      name: name,
      termsAccepted: termsAccepted,
    });

    const redirectToDashboard = data?.redirectToDashboard;

    console.log(redirectToDashboard, "redirectToDashboard")

    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      toast.success("Signed up successfully!")
      if (redirectToDashboard) {
        window.location.href = "/dashboard"
        return false
      }
      return true;
    }
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 400) {
        toast.error(data.message)
      }
    } else {
      toast.error("Something went wrong!")
    }
    return false;
  }
};


export const handleSignIn = async ({ email, password }) => {
  if (!email || !password) {
    toast.error("Please fill all the fields")
    return false;
  }
  try {
    email = cleanEmail(email);
    password = cleanPassword(password);

    const { data, status } = await instance.post("/user/signin", {
      email: email,
      password: password,
    });
    console.log(data);
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);

      // if (data.redirectToPayment) {
      //   toast.error("No Valid Subscription found!")
      //   handlePayment();
      //   return false;
      // }

      if (data.redirectToPayment) {
        // toast.error("No Active Subscription found!")
        return "NOT_SUBSCRIBED";
      }

      // toast.success("Logged in successfully!");
      return true; // Indicate a successful login
    } else {
      return false;
    }
  } catch (error) {
    console.log("error occured")
    if (error.response) {
      console.log(error.response);
      const { status, data } = error.response;
      if (status === 400) {
        toast.error(data.message);
      }
    } else {
      toast.error("Something went wrong!")
    }
    return false;
  }

};

export const handleAdminSignIn = async ({ email, password }) => {
  if (!email || !password) {
    toast.error("Please fill all the fields")
    return false;
  }
  try {
    email = cleanEmail(email);
    password = cleanPassword(password);

    const { data, status } = await instance.post("/user/admin-signin", {
      email: email,
      password: password,
    });
    console.log(data);
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      toast.success("Logged in successfully!");
      return true; // Indicate a successful login
    } else {
      return false;
    }
  } catch (error) {
    console.log("error occured")
    if (error.response) {
      console.log(error.response);
      const { status, data } = error.response;
      if (status === 400) {
        toast.error(data.message);
      }
    } else {
      toast.error("Something went wrong!")
    }
    return false;
  }
}

export const handleLogout = async (dispatch) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  dispatch({
    type: 'LOGOUT',
  });
  toast.success("Logged out successfully!");
}

export const fetchUser = () => {
  return async (dispatch) => {
    try {
      const { data, status } = await authenticatedInstance.get("/user");
      console.log(data);
      if (status === 200) {
        // Dispatch the action to update categories in the Redux store
        dispatch({
          type: 'FETCH_USER',
          payload: data,
        });
      }
    } catch (error) {
      console.log(error, "User could not be fetched");
      toast.error("Could not fetch User!");

    }
  };
}

export const fetchUserFirst = () => {
  return async (dispatch) => {
    try {
      const { data, status } = await authenticatedInstance.get("/user");
      console.log(data);
      if (status === 200) {
        // Dispatch the action to update categories in the Redux store
        dispatch({
          type: 'FETCH_USER',
          payload: data,
        });

        dispatch({
          type: 'UPDATE_STRIPE',
          payload: data.stripeData,
        })
      }
    } catch (error) {
      console.log(error, "User could not be fetched");
      // toast.error("Could not fetch User!");
    }
  };
}

