import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { SearchData } from "./SearchData";
import _debounce from 'lodash/debounce';
import { useDispatch, useSelector } from "react-redux";
import "../../styles/style.css"

// icons
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export const CopyGenerator = ({ sidebarClosingStatus }) => {
  const navigate = useNavigate();
  const columns = ["Name", "Date", "Results", " "];
  const inventoryData = useSelector((state) => state.copyDetails.generatedData);
  const [searchInput, setSearchInput] = useState('');
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const paragraphs = document.querySelectorAll('.fade-in');
    paragraphs.forEach((p) => {
      p.classList.add('animate-fade-in');
    });
  }, []);

  useEffect(() => {
    const debouncedSearch = _debounce(() => {
      if (inventoryData.length === 0) {
        setTableData([])
      } else {
        const filteredData = inventoryData.filter(item =>
          item.product_name.toLowerCase().includes(searchInput.toLowerCase())
        );
        setTableData(filteredData);
      }
    }, 300);

    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [searchInput, inventoryData]);

  const isMobileScreen = window.innerWidth < 768;
  return (
    <div style={isMobileScreen ? {
      width: "100vw"
    } : {}}>

      {
        isMobileScreen ?
          <div className="px-5 py-3.5 font-Inter">
            <div className="flex justify-between">
              <div className="title">AI Copy Creator</div>
              <div>
                <button
                  className="bg-primary text-white rounded-xl outline-none py-2 px-4 flex items-center gap-x-2"
                  onClick={() => navigate("/copy-generator/create-copy")}
                >
                  {isMobileScreen === false && <AiOutlinePlus />}
                  <p>{isMobileScreen === false && "Create "}New Copy</p>
                </button>
              </div>
            </div>
            {/* <hr className="bg-gray-200 h-[1.15px] mt-1" /> */}
            <div className={`flex justify-end mt-2`}>
              <input
                className="bg-[#F9FAFB] rounded-lg w-full placeholder-[#6B7280] outline-none py-1 px-3 border border-[#E5E7EB]"
                placeholder="Search"
                maxLength={200}
                onChange={(e) => setSearchInput(e.target.value)}
              />

            </div>
          </div>
          :
          <div
            className={`${isMobileScreen ? "px-4" : "px-12"} fade-in py-4 flex ${sidebarClosingStatus ? "w-full" : "w-full"
              } justify-between items-center font-Inter`}
          >
            <div className="flex items-center space-x-3 w-[60%]">
              <p className="title">AI Copy Creator</p>
              <div className="bg-white border-[#E5E7EB] border px-5 py-2 flex items-center space-x-2 rounded-full w-1/2 text-[#6B7280]">
                <AiOutlineSearch className="w-5 h-5" />
                <input
                  className="w-full placeholder-[#6B7280] outline-none text-gray-800 bg-white"
                  placeholder="Search"
                  maxLength={200}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            </div>
            <button
              className="bg-primary text-white rounded-xl outline-none py-2 px-4 flex items-center gap-x-2"
              onClick={() => navigate("/copy-generator/create-copy")}
            >
              {isMobileScreen === false && <AiOutlinePlus />}
              <p>{isMobileScreen === false && "Create "}New Copy</p>
            </button>
          </div>
      }
      <hr className="bg-gray-200 h-[1.15px] mt-1" />
      <SearchData tableData={tableData} />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CopyGenerator);