import React, { useState, useEffect } from "react";
import { Modal } from "./Modal";
import { AiOutlineCloseCircle, AiOutlineSearch } from "react-icons/ai";
import CustomSelect from "./CustomSelect";
import CustomRange from "./CustomRange";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';


const FilterModal = ({ setShowFilter }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const suppliers = useSelector((state) => state.filters.suppliers);

  const rangeString = searchParams.get('range');
  const defaultRange = rangeString ? rangeString.split('-').map(Number) : [0, 100];
  const shipFrom = searchParams.get('shipFrom');
  const shipTo = searchParams.get('shipTo');
  const shipToTime = searchParams.get('shipToTime');
  const supplier = searchParams.get('supplier');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isMobileScreen = windowWidth < 768;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const filterDataDefaults = {
    range: `${defaultRange[0]}-${defaultRange[1]}`,
    shipFrom: shipFrom || '',
    shipTo: shipTo || '',
    shipToTime: shipToTime || '',
    supplier: supplier || '',
  };
  const [filterData, setFilterData] = useState(filterDataDefaults);
  const [selectedTime, setSelectedTime] = useState(null);
  const [range, setRange] = useState(defaultRange);

  const ShippingTimes = [
    ["Any Days", null],
    ["1-3 Days", "1-3"],
    ["4-7 Days", "4-7"],
    ["8-14 Days", "8-14"],
    ["15+ Days", "15+"]
  ];
  const countries = [
    "Worldwide",
    "United States",
    "Europe",
    "Canada",
    "United Kingdom",
    "Australia",
    "Bulgaria",
    "China",
    "India",
    "Ireland",
  ];

  const shipFromHandler = (value) => {
    console.log(value)
    setFilterData({ ...filterData, shipFrom: value });
  };

  const shipToHandler = (value) => {
    setFilterData({ ...filterData, shipTo: value });
  };

  const shipToTimeHandler = (value) => {
    setFilterData({ ...filterData, shipToTime: value });
  };

  const supplierHandler = (value) => {
    setFilterData({ ...filterData, supplier: value });
  };

  const handleRange = (value) => {
    setRange(value);
    console.log(value);
    const min = value[0];
    const max = value[1];
    const rangeStr = `${min}-${max}`;
    setFilterData({ ...filterData, range: rangeStr });
  }

  const filterHandler = () => {
    for (const key in filterData) {
      if (filterData[key] === null || filterData[key] === '') {
        delete filterData[key];
      }
    }
    console.log(filterData);
    setSearchParams(filterData);
    setShowFilter(false)
    // dispatch({
    //   type: 'LOADING',
    //   payload: true,
    // })
  }

  const modalWidthProvider = () => {
    if (windowWidth > 1024) {
      return "w-1/2";
    } else if (windowWidth > 768) {
      return "w-1/2";
    } else {
      return "w-full";
    }
  };

  return (
    <Modal handleClose={() => setShowFilter(false)} width={modalWidthProvider()} padding="px-0">
      <div className="flex items-center justify-between w-full pt-1 pb-2 px-6">
        <h1 className="text-2xl text-gray-800 font-bold">Filters</h1>
        <AiOutlineCloseCircle
          className="w-6 h-6 text-gray-500 cursor-pointer"
          onClick={() => setShowFilter(false)}
        />
      </div>

      <div className="px-6 py-6 w-full flex flex-col space-y-3 h-[26rem] overflow-y-scroll filterScroll">
        <div className="border border-gray-300 rounded-lg p-3 flex flex-col space-y-3">
          <h2 className="text-gray-800 font-semibold text-lg">Shipping</h2>
          <div className="flex space-x-2 w-full">
            <div className="flex flex-col space-y-4 w-[60%]">
              <div className="flex flex-col space-y-2">
                <p className="text-sm font-medium">Ship From</p>
                <CustomSelect value={filterData?.shipFrom} filterData={filterData} setFilterData={shipFromHandler} data={countries} placeholder="Worldwide" />
              </div>
              <div className="flex flex-col space-y-2">
                <p className="text-sm font-medium">Ship To
                {
                (filterData.shipTo === null || filterData.shipTo === '') &&
                <p className="text-xs my-0 text-red-500" style={{
                  margin : "0",
                }}>select "Ship To" value first to select Shipping Time</p>
              }
                </p>

                <CustomSelect value={filterData?.shipTo} filterData={filterData} setFilterData={shipToHandler} data={countries} placeholder="Worldwide" />
              </div>
            </div>
            <div className="flex flex-col space-y-2 w-[40%] items-center">
              <h2 className="text-sm font-medium pr-2">Shipping Time</h2>
              {ShippingTimes.map((item, i) => {
                return (
                  <div class="flex items-center space-x-2" key={i}>
                    <input
                      id={`default-radio-${i}`}
                      type="radio"
                      value={filterData?.shipToTime || ''}
                      name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-200 border-gray-300 outline-none"
                      onChange={() => {
                        setSelectedTime(i); // Update the selectedTime state
                        setFilterData({ ...filterData, shipToTime: item[1] });
                      }}
                      checked={i === selectedTime}
                      disabled={filterData.shipTo === null || filterData.shipTo === ''}
                    />
                    <label for={`default-radio-${i}`} class="w-20 text-gray-800">
                      {item[0]}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="border border-gray-300 rounded-lg p-3 flex flex-col space-y-3">
          <h2 className="text-gray-800 font-semibold text-lg">Items Cost</h2>
          <div className="flex space-x-2 w-full">
            <div className={`${isMobileScreen ? "w-full" : "w-1/2"} flex flex-col`}>
              <p className="text-sm font-medium">Item Cost</p>
              <CustomRange range={range} handleRange={handleRange} />
              <div className="flex items-center justify-between text-sm font-medium">
                <p>$0</p>
                <p className="ml-20">$100+</p>
              </div>
            </div>
          </div>
        </div>
        <div className="border border-gray-300 rounded-lg p-3 flex flex-col space-y-4">
          <h2 className="text-gray-800 font-semibold text-lg">Supplier</h2>
          <div className="flex space-x-2 w-full">
            <div className={`${isMobileScreen ? "w-full" : "w-1/2"} flex flex-col`}>
              <CustomSelect value={filterData?.supplier} filterData={filterData} setFilterData={supplierHandler} data={suppliers} placeholder="Select Supplier" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full pt-4 pb-2 px-8">
        <button
          className="px-8 py-2 rounded-lg text-primary bg-gray-100 border border-gray-300 hover:bg-gray-200 hover:border-gray-400/80"
          onClick={() => setShowFilter(false)}
        >
          Cancel
        </button>
        <button onClick={filterHandler} className="bg-primary text-white rounded-lg outline-none px-3 py-2 flex items-center gap-x-1 hover:bg-blue-700/90">
          <p>View</p>
        </button>
      </div>
    </Modal>
  );
};

export default FilterModal;
