import React, { useEffect, useState } from "react";
import "../styles/Category.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Categories from "../data/Categories";
import CategoryCard from "./CategoryCard";
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories, selectCategory } from '../redux/actions/category';
import { useLocation, Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BsHeadphones } from "react-icons/bs";

const CategoryCorousel = ({ sidebarClosingStatus }) => {
  const params = useParams();
  const main_categories = useSelector((state) => state.category.main_categories);
  const sub_categories = useSelector((state) => state.category.sub_categories);
  const selectedCategory = useSelector((state) => state.category.selectedCategory);
  const selectedSubcategory = useSelector((state) => state.category.selectedSubcategory);
  const mainCategory = params.mainCategory;
  const subCategory = params.subCategory;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const categoryHandler = (category) => {
    // dispatch({
    //   type: 'LOADING',
    //   payload: true,
    // })

    dispatch({
      type: 'SELECT_CATEGORY',
      payload: null,
    })

    dispatch({
      type: 'SELECT_SUB_CATEGORY',
      payload: subCategory,
    })

    // get the query params
    let queryParams = "";
    for (const [key, value] of searchParams.entries()) {
      queryParams += `${key}=${value}&`;
    }
    queryParams = queryParams.substring(0, queryParams.length - 1);

    // navigate to the new url
    if (!category) {
      navigate(`/dashboard/?${queryParams}`)
      return
    }

    console.log(category)
    navigate(`/dashboard/${category?.name}/?${queryParams}`)
  };

  const subCategoryHandler = (subCategory) => {
    // dispatch({
    //   type: 'LOADING',
    //   payload: true,
    // })

    dispatch({
      type: 'SELECT_SUB_CATEGORY',
      payload: subCategory,
    })

    // get the query params
    let queryParams = "";
    for (const [key, value] of searchParams.entries()) {
      queryParams += `${key}=${value}&`;
    }
    queryParams = queryParams.substring(0, queryParams.length - 1);

    // navigate to the new url
    if (!subCategory) {
      navigate(`/dashboard/${mainCategory}/?${queryParams}`)
      return
    }
    navigate(`/dashboard/${mainCategory}/${subCategory.name}/?${queryParams}`)
  }


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: sidebarClosingStatus ? 5 : 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const isMobileScreen = window.innerWidth < 768;
  
  return (
    <div className={`flex pt-5 ${isMobileScreen ? "pr-4" : "px-5"} flex-col`} style={{
      maxWidth: '100vw',
    }}>
      <Carousel
        responsive={responsive}
        customLeftArrow={
          <div className="p-1 rounded-full w-[] bg-gray-200 text-gray-600 left-0 absolute cursor-pointer">
            <BiChevronLeft className="h-6 w-6" />
          </div>
        }
        customRightArrow={
          <div className="p-1 rounded-full bg-gray-200 text-gray-600 right-0 absolute cursor-pointer">
            <BiChevronRight className="h-6 w-6" />
          </div>
        }
        className="w-full"
      >
        {main_categories.map((category, i) => {
          return <CategoryCard
            categoryHandler={categoryHandler}
            category={category}
            mainCategory={mainCategory}
            key={i}
            title={category.name}
          />
        })}
      </Carousel>
      {mainCategory &&
        sub_categories?.length > 0 ? (
        <div className={`w-full ${isMobileScreen ? "" : "py-2"} px-1 flex flex-col`}>
          <p className="text-gray-800 text-xl font-semibold">

            {/* {mainCategory} */}
          </p>
          <ul className="breadcrumb capitalize margin-top-px margin-bottom-px">
            <li><p className="text-gray-500" onClick={() => { categoryHandler(null) }}>Home </p></li>
            {mainCategory && (
              <li><p onClick={() => { subCategoryHandler(null) }}>{mainCategory} </p></li>
            )}
            {mainCategory && subCategory && (
              <li><p>{subCategory}</p></li>
            )}
          </ul>

          <div className="flex items-center flex-wrap">
            {sub_categories
              .filter((subcat) => subcat.mainCategoryId === main_categories.find((cat) => cat?.name === mainCategory)?.id)
              .map((subcat, i) => {
                return (
                  <p
                    onClick={() => {
                      subCategoryHandler(
                        subCategory === subcat.name ? null : subcat
                      );
                    }}
                    key={i}
                    className={`capitalize inline-block px-4 py-2 rounded-full text-xs font-bold leading-5 text-gray-800 cursor-pointer transition duration-300 ease-out mr-2 mb-2 relative hover:bg-gray-200/70 ${selectedSubcategory?.id === subcat.id
                      ? "bg-primary-light border border-primary text-primary shadow-md"
                      : "bg-gray-100 border border-gray-300 text-gray-600"
                      }`}
                  >
                    {subcat.name}
                  </p>

                );
              })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CategoryCorousel;
