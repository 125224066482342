const initialState = {
  products: [],
  totalProducts: 0,
  itemsPerPage:16,
};

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_PRODUCTS':
      return {
        ...state,
        products: action.payload,
      };
    case 'TOTAL_PRODUCTS':
      console.log(action.payload, "totalProducts")
      return {
        ...state,
        totalProducts: action.payload,
      };
    default:
      return state;
  }
}
