import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import Products from "../../data/Products";
import { AiOutlinePlus } from "react-icons/ai";
import ZendropModal from "../../components/ZendropModal";
import "photoswipe/dist/photoswipe.css";
import axios from "axios";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackButton from "../../components/BackButton";
import { fetchProductDetails } from "../../redux/actions/productsActions";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { connect } from 'react-redux'

export const Product = (props) => {

  const { fetchProductDetails } = props;

  const [sidebarClosingStatus, setSidebarClosingStatus] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [product, setProduct] = useState(null);
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentRoute = location.pathname.slice(1);


  const fetchProduct = async () => {
    const res = await fetchProductDetails(params.id);
    if (res.status) {
      setProduct(res.data);
    }
  }

  React.useEffect(() => {
    const paragraphs = document.querySelectorAll('.fade-in');
    paragraphs.forEach((p) => {
      p.classList.add('animate-fade-in');
    });
  }, []);

  useEffect(() => {

    fetchProduct();
    setTimeout(() => {
      dispatch(
        {
          type: 'LOADING',
          payload: false,
        }
      )
    }, 250);

  }, [])

  console.log(product, 'pro')

  // trim the description & return policy

  if (product) {
    product.description = product.description?.trim();
    product.return_policy = product.return_policy?.trim();
  }

  const isMobileScreen = () => {
    return window.innerWidth < 768; // Adjust the breakpoint as needed
  };

  return (
    <>
      <div className="">
        {/* <div className={` ${sidebarClosingStatus ? `ml-[240px] w-full` : `ml-[80px] w-full`}`} style={{
          zIndex: 1,
          maxWidth: '100vw'
        }}> */}
        <div
          className={`px-6 py-4 flex ${sidebarClosingStatus ? "w-full" : "w-full"
            } justify-between items-center font-Inter`}
        >
          <div className="flex items-center space-x-3 w-[60%]">
            <BackButton /><p className="title capitalize">{product?.name}</p>
          </div>

        </div>
        <hr className="bg-gray-200 h-[1.15px] mt-1" />
        <div class="flex flex-col md:flex-row w-full px-3 py-10 fade-in">
          <div class="w-60% sm:w-full p-3 flex flex-col md:space-y-5">
            {/* <img src={Products[params.id].src} className="w-[60%]" />
              <div className="w-[40%] flex flex-col space-y-2 items-center">
                <img src={Products[params.id].src} className="w-3/4 h-1/2" />
                <img src={Products[params.id].src} className="w-3/4 h-1/2" />
                <img src={Products[params.id].src} className="w-3/4 h-1/2" />
              </div> */}
            <Gallery>
              <div className="flex items-center space-x-2">
                <div className="w-[65%]">
                  <Item
                    original={product?.image[0]}
                    thumbnail={product?.image[0]}
                    width="1600"
                    height="1600"
                    alt="Photo of seashore by Folkert Gorter"
                  >
                    {({ ref, open }) => (
                      <img
                        style={{ cursor: "pointer" }}
                        src={product?.image[0]}
                        ref={ref}
                        onClick={open}
                        className="w-full bg-cover max-w-[400px] max-h-[400px] rounded-md"
                      />
                    )}
                  </Item>
                </div>
                <div className="flex flex-col space-y-2 items-center w-[30%]">
                  {/* <Item
                    original={product?.image[1]}
                    thumbnail={product?.image[1]}
                    width="1600"
                    height="1600"
                    alt="Photo of seashore by Folkert Gorter"
                  >
                    {({ ref, open }) => (
                      <img
                        style={{ cursor: "pointer" }}
                        src={product?.image[1]}
                        ref={ref}
                        onClick={open}
                        className="w-[80%] rounded-md"
                      />
                    )}
                  </Item> */}
                  {product?.image.length >= 2 && (
                    console.log(product?.image),
                    <Item
                      original={product?.image[1]}
                      thumbnail={product?.image[1]}
                      width="1600"
                      height="1600"
                      alt="Ecom Products"
                    >
                      {({ ref, open }) => (
                        <img
                          style={{ cursor: "pointer" }}
                          src={product?.image[1]}
                          ref={ref}
                          onClick={open}
                          className="w-[80%] rounded-md"
                        />
                      )}
                    </Item>
                  )}
                  {product?.image.length >= 3 ? (
                    console.log(product?.image.length),
                    <Item
                      original={product?.image[2]}
                      thumbnail={product?.image[2]}
                      width="1600"
                      height="1600"
                      alt="Photo of seashore by Folkert Gorter"
                    >
                      {({ ref, open }) => (
                        <img
                          style={{ cursor: "pointer" }}
                          src={product?.image[2]}
                          ref={ref}
                          onClick={open}
                          className="w-[80%] rounded-md"
                        />
                      )}
                    </Item>
                  ) : null}
                  {product?.image.length > 3 ? (
                    <>
                      {console.log(product?.image.length)}
                      <Item
                        original={product?.image[2]}
                        thumbnail={product?.image[2]}
                        width="1600"
                        height="1600"
                        alt="Photo of seashore by Folkert Gorter"
                      >
                        {({ ref, open }) => (
                          <div
                            ref={ref}
                            onClick={open}
                            className="relative h-32 w-[80%] cursor-pointer"
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={product?.image[2]}
                              className="w-full rounded-md absolute h-full"
                            />
                            <div className="w-full bg-gray-800 opacity-90 h-full flex items-center justify-center rounded-md">
                              <p className="text-white text-3xl font-bold">
                                +{product?.image.length - 2}
                              </p>
                            </div>
                          </div>
                        )}
                      </Item>
                      {product?.image
                        .slice(3, product?.image.length)
                        .map((variation, i) => {
                          return (
                            <Item
                              original={variation}
                              thumbnail={variation}
                              width="1600"
                              height="1600"
                              alt="Photo of seashore by Folkert Gorter"
                              key={i}
                            >
                              {({ ref, open }) => (
                                <span ref={ref} onClick={open}></span>
                              )}
                            </Item>
                          );
                        })}
                    </>
                  ) : null}
                </div>
              </div>
            </Gallery>
            <div className="flex flex-col space-y-2">
              <h2 className="text-gray-800 font-semibold text-xl">
                {(product?.desc_heading !== "" && product?.desc_heading !== null) ? product?.desc_heading : "Product Description"}
              </h2>
              <p className="text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: product?.description !== "" ? product?.description : "Product Description not available" }}></p>
              {
                (product?.benefits?.trim() !== "" && product?.benefits !== null) ? (
                  <>
                    <h3 className="text-gray-800 font-semibold text-xl">
                      Benefits
                    </h3>
                    <p className="text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: product?.benefits }}></p>
                  </>
                ) : null
              }
              {
                (product?.ad_copy?.trim() !== "" && product?.ad_copy !== null) ? (
                  <>
                    <h3 className="text-gray-800 font-semibold text-xl">
                      Ad Copy
                    </h3>
                    <p className="text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: product?.ad_copy }}></p>
                  </>
                ) : null
              }
              {
                (product?.sales_angle?.trim() !== "" && product?.sales_angle !== null) ? (
                  <>
                    <h3 className="text-gray-800 font-semibold text-xl">
                      Sales Angle
                    </h3>
                    <p className="text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: product?.sales_angle }}></p>
                  </>
                ) : null
              }

            </div>
          </div>
          {/* Description */}
          <div className="flex flex-col w-40% sm:w-[100%] px-8 py-6 border border-gray-300 rounded-lg space-y-3">
            <div className="flex flex-col">
              <h1 className="text-gray-800 font-semibold text-lg capitalize">
                {product?.name}
              </h1>
              <p className="text-gray-500 text-sm">
                By{" "}
                <Link
                  className="text-primary hover:underline capitalize"
                  to={`/supplier/${product?.supplier?.id}`}
                >
                  {product?.supplier?.name}
                </Link>
              </p>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-sm text-gray-500">Listing Cost</p>
                <p className="text-gray-900 font-semibold">
                  ${product?.listing_cost}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="text-sm text-gray-500">Retail Price</p>
                <p className="text-gray-900 font-semibold">
                  ${product?.sale_price}
                </p>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <button
                className="bg-primary text-white rounded-xl outline-none py-2 flex items-center gap-x-2 w-full justify-center hover:bg-blue-700/90"
                onClick={() => setShowModal(true)}
              >
                <AiOutlineShoppingCart /> <p>Go to Zendrop</p>
              </button>
              {/* <button className="px-4 py-2 rounded-lg text-gray-800 bg-gray-200 w-full hover:bg-gray-300/80">
                Order Samples
              </button> */}
            </div>
            {
              product?.processing_time !== "" && product?.processing_time > 0 &&
              <div>
                <h2 className="text-gray-800 font-semibold">Processing Time</h2>
                <p className="text-gray-500 text-sm">
                  {product?.processing_time} business days
                </p>
              </div>
            }
            <hr className="text-gray-700 border" />
            <div className="flex flex-col space-y-2">
              <h2 className="text-gray-800 font-semibold">{<FontAwesomeIcon icon={'shipping-fast'} />} Shipping Time</h2>
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <p className="text-gray-800">{<FontAwesomeIcon icon={'location-dot'} />} United States</p>
                  <p className="text-sm text-gray-500"> {product?.us_shipping_time} business days</p>
                </div>
                {
                  product?.us_shipping_cost !== "" && product?.us_shipping_cost !== null &&
                  product?.us_shipping_cost !== 0 &&
                  <p className="font-semibold text-lg">${product?.us_shipping_cost}</p>
                }
              </div>
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <p className="text-gray-800">{<FontAwesomeIcon icon={'globe'} />} Worldwide</p>
                  <p className="text-sm text-gray-500"> {product?.ww_shipping_time} business days</p>
                </div>

                {
                  product?.ww_shipping_cost !== "" && product?.ww_shipping_cost !== null &&
                  product?.ww_shipping_cost !== 0 &&
                  <p className="font-semibold text-lg">${product?.ww_shipping_cost}</p>
                }

              </div>
            </div>
            <hr className="text-gray-700 border" />
            <div className="flex flex-col space-y-2">
              <h2 className="text-gray-800 font-semibold">Return Policy</h2>
              <p className="text-sm text-gray-500">
                {product?.return_policy !== "" ? (
                  product?.return_policy
                ) : (
                  <p>{"No return policy available"}</p>
                )}
              </p>
            </div>
          </div>
        </div>
        {showModal ? <ZendropModal link={product?.link} setShowModal={setShowModal} /> : null}
        {/* </div> */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { fetchProductDetails }

export default connect(mapStateToProps, mapDispatchToProps)(Product)
