const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
const SELECT_CATEGORY = 'SELECT_CATEGORY';

const initialState = {
  main_categories: [],
  sub_categories: [],
  collections: [],
  selectedCategory: null,
  selectedSubcategory: null,
  selectedCollection: null,
};

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return {
        ...state,
        main_categories: action.main_categories,
        sub_categories: action.sub_categories,
        collections: action.collections,
      };
    case SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case 'SELECT_SUB_CATEGORY':
      return {
        ...state,
        selectedSubcategory: action.payload,
      };  
    default:
      return state;
  }
}
