import React from 'react'
import { connect } from 'react-redux'

export const MainSection = (props) => {
    return (
        <div class="min-w-0 max-w-2xl flex-auto px-4 py-16 overflow-y-scroll lg:max-w-none lg:pl-8 lg:pr-0 xl:px-16 bg-white">
            <article>
                <header class="mb-9 space-y-1">
                    <h1 class="font-display text-3xl tracking-tight font-[600] text-slate-900 ">Use Ecommerce Product like a Pro</h1>
                </header>
                <div class="prose prose-slate max-w-none  prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-slate-500  hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:bg-slate-900 prose-pre:shadow-lg ">
                    <p>Learn all of the features Ecommerce Product has to offer so you can use it throughout the deal cycle and close more deals faster.</p>
                    {/* <iframe src="https://www.youtube.com/embed/zblG4f8bEMI" class="w-full h-96" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe> */}
                    <h2 id="quick-start">Quick start</h2>
                    <div class="not-prose my-12 grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div class="group relative rounded-xl border border-slate-200 hover:border-2 hover:border-primary hover:bg-primary-light hover:bg-opacity-30">
                            {/* <div class="absolute -inset-px rounded-xl border-2 border-transparent opacity-0 [background:linear-gradient(var(--quick-links-hover-bg,theme(colors.sky.50)),var(--quick-links-hover-bg,theme(colors.sky.50)))_padding-box,linear-gradient(to_top,theme(colors.indigo.400),theme(colors.cyan.400),theme(colors.sky.500))_border-box] group-hover:opacity-100"></div> */}
                            <div class="relative overflow-hidden rounded-xl p-6">
                                <svg aria-hidden="true" viewBox="0 0 32 32" fill="none" class="h-8 w-8 [--icon-foreground:theme(colors.slate.900)] [--icon-background:theme(colors.white)]">
                                    <defs>
                                        <radialGradient cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" id=":R669l6:-gradient" gradientTransform="matrix(0 21 -21 0 12 3)">
                                            <stop stop-color="#615AA7"></stop>
                                            <stop stop-color="#615AA7" offset=".527"></stop>
                                            <stop stop-color="#998fff" offset="1"></stop>
                                        </radialGradient>
                                        <radialGradient cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" id=":R669l6:-gradient-dark" gradientTransform="matrix(0 21 -21 0 16 7)">
                                            <stop stop-color="#615AA7"></stop>
                                            <stop stop-color="#615AA7" offset=".527"></stop>
                                            <stop stop-color="#998fff" offset="1"></stop>
                                        </radialGradient>
                                    </defs>
                                    <g class="">
                                        <circle cx="12" cy="12" r="12" fill="url(#:R669l6:-gradient)"></circle>
                                        <path d="m8 8 9 21 2-10 10-2L8 8Z" fill-opacity="0.5" class="fill-[var(--icon-background)] stroke-[color:var(--icon-foreground)]" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </g>
                                    <g class="hidden">
                                        <path d="m4 4 10.286 24 2.285-11.429L28 14.286 4 4Z" fill="url(#:R669l6:-gradient-dark)" stroke="url(#:R669l6:-gradient-dark)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </g>
                                </svg>
                                <h2 class="mt-4 font-display text-base text-slate-900 "><p><span class="absolute -inset-px rounded-xl"></span>Product</p></h2>
                                <p class="mt-1 text-sm text-slate-700 ">Learn how to find more product fast.</p>
                            </div>
                        </div>
                        <div class="group relative rounded-xl border border-slate-200 hover:border-2 hover:border-primary hover:bg-primary-light hover:bg-opacity-30">
                            {/* <div class="absolute -inset-px rounded-xl border-2 border-transparent opacity-0 [background:linear-gradient(var(--quick-links-hover-bg,theme(colors.sky.50)),var(--quick-links-hover-bg,theme(colors.sky.50)))_padding-box,linear-gradient(to_top,theme(colors.indigo.400),theme(colors.cyan.400),theme(colors.sky.500))_border-box] group-hover:opacity-100"></div> */}
                            <div class="relative overflow-hidden rounded-xl p-6">
                                <svg aria-hidden="true" viewBox="0 0 32 32" fill="none" class="h-8 w-8 [--icon-foreground:theme(colors.slate.900)] [--icon-background:theme(colors.white)]">
                                    <defs>
                                        <radialGradient cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" id=":R6a9l6:-gradient" gradientTransform="matrix(0 21 -21 0 20 3)">
                                            <stop stop-color="#615AA7"></stop>
                                            <stop stop-color="#615AA7" offset=".527"></stop>
                                            <stop stop-color="#998fff" offset="1"></stop>
                                        </radialGradient>
                                        <radialGradient cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" id=":R6a9l6:-gradient-dark" gradientTransform="matrix(0 22.75 -22.75 0 16 6.25)">
                                            <stop stop-color="#615AA7"></stop>
                                            <stop stop-color="#615AA7" offset=".527"></stop>
                                            <stop stop-color="#998fff" offset="1"></stop>
                                        </radialGradient>
                                    </defs>
                                    <g class="">
                                        <circle cx="20" cy="12" r="12" fill="url(#:R6a9l6:-gradient)"></circle>
                                        <g class="fill-[var(--icon-background)] stroke-[color:var(--icon-foreground)]" fill-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M3 5v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2Z"></path>
                                            <path d="M18 17v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V17a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2Z"></path>
                                            <path d="M18 5v4a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2Z"></path>
                                            <path d="M3 25v2a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2Z"></path>
                                        </g>
                                    </g>
                                    <g class="hidden" fill="url(#:R6a9l6:-gradient-dark)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 17V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm16 10v-9a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2Zm0-23v5a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1ZM3 28v-3a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Z"></path>
                                        <path d="M2 4v13h2V4H2Zm2-2a2 2 0 0 0-2 2h2V2Zm8 0H4v2h8V2Zm2 2a2 2 0 0 0-2-2v2h2Zm0 13V4h-2v13h2Zm-2 2a2 2 0 0 0 2-2h-2v2Zm-8 0h8v-2H4v2Zm-2-2a2 2 0 0 0 2 2v-2H2Zm16 1v9h2v-9h-2Zm3-3a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1v-2Zm6 0h-6v2h6v-2Zm3 3a3 3 0 0 0-3-3v2a1 1 0 0 1 1 1h2Zm0 9v-9h-2v9h2Zm-3 3a3 3 0 0 0 3-3h-2a1 1 0 0 1-1 1v2Zm-6 0h6v-2h-6v2Zm-3-3a3 3 0 0 0 3 3v-2a1 1 0 0 1-1-1h-2Zm2-18V4h-2v5h2Zm0 0h-2a2 2 0 0 0 2 2V9Zm8 0h-8v2h8V9Zm0 0v2a2 2 0 0 0 2-2h-2Zm0-5v5h2V4h-2Zm0 0h2a2 2 0 0 0-2-2v2Zm-8 0h8V2h-8v2Zm0 0V2a2 2 0 0 0-2 2h2ZM2 25v3h2v-3H2Zm2-2a2 2 0 0 0-2 2h2v-2Zm9 0H4v2h9v-2Zm2 2a2 2 0 0 0-2-2v2h2Zm0 3v-3h-2v3h2Zm-2 2a2 2 0 0 0 2-2h-2v2Zm-9 0h9v-2H4v2Zm-2-2a2 2 0 0 0 2 2v-2H2Z"></path>
                                    </g>
                                </svg>
                                <h2 class="mt-4 font-display text-base text-slate-900 "><p><span class="absolute -inset-px rounded-xl"></span>Suppliers</p></h2>
                                <p class="mt-1 text-sm text-slate-700 ">Learn how to find more Suppliers fast..</p>
                            </div>
                        </div>
                        <div class="group relative rounded-xl border border-slate-200 hover:border-2 hover:border-primary hover:bg-primary-light hover:bg-opacity-30">
                            {/* <div class="absolute -inset-px rounded-xl border-2 border-transparent opacity-0 [background:linear-gradient(var(--quick-links-hover-bg,theme(colors.sky.50)),var(--quick-links-hover-bg,theme(colors.sky.50)))_padding-box,linear-gradient(to_top,theme(colors.indigo.400),theme(colors.cyan.400),theme(colors.sky.500))_border-box] group-hover:opacity-100 "></div> */}
                            <div class="relative overflow-hidden rounded-xl p-6">
                                <svg aria-hidden="true" viewBox="0 0 32 32" fill="none" class="h-8 w-8 [--icon-foreground:theme(colors.slate.900)] [--icon-background:theme(colors.white)]">
                                    <defs>
                                        <radialGradient cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" id=":R6e9l6:-gradient" gradientTransform="matrix(0 21 -21 0 20 11)">
                                            <stop stop-color="#615AA7"></stop>
                                            <stop stop-color="#615AA7" offset=".527"></stop>
                                            <stop stop-color="#998fff" offset="1"></stop>
                                        </radialGradient>
                                        <radialGradient cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" id=":R6e9l6:-gradient-dark-1" gradientTransform="matrix(0 22.75 -22.75 0 16 6.25)">
                                            <stop stop-color="#615AA7"></stop>
                                            <stop stop-color="#615AA7" offset=".527"></stop>
                                            <stop stop-color="#998fff" offset="1"></stop>
                                        </radialGradient>
                                        <radialGradient cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" id=":R6e9l6:-gradient-dark-2" gradientTransform="matrix(0 14 -14 0 16 10)">
                                            <stop stop-color="#615AA7"></stop>
                                            <stop stop-color="#615AA7" offset=".527"></stop>
                                            <stop stop-color="#998fff" offset="1"></stop>
                                        </radialGradient>
                                    </defs>
                                    <g class="">
                                        <circle cx="20" cy="20" r="12" fill="url(#:R6e9l6:-gradient)"></circle>
                                        <g fill-opacity="0.5" class="fill-[var(--icon-background)] stroke-[color:var(--icon-foreground)]" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M3 9v14l12 6V15L3 9Z"></path>
                                            <path d="M27 9v14l-12 6V15l12-6Z"></path>
                                        </g>
                                        <path d="M11 4h8v2l6 3-10 6L5 9l6-3V4Z" fill-opacity="0.5" class="fill-[var(--icon-background)]"></path>
                                        <g class="stroke-[color:var(--icon-foreground)]" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M20 5.5 27 9l-12 6L3 9l7-3.5"></path>
                                            <path d="M20 5c0 1.105-2.239 2-5 2s-5-.895-5-2m10 0c0-1.105-2.239-2-5-2s-5 .895-5 2m10 0v3c0 1.105-2.239 2-5 2s-5-.895-5-2V5"></path>
                                        </g>
                                    </g>
                                    <g class="hidden" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M17.676 3.38a3.887 3.887 0 0 0-3.352 0l-9 4.288C3.907 8.342 3 9.806 3 11.416v9.168c0 1.61.907 3.073 2.324 3.748l9 4.288a3.887 3.887 0 0 0 3.352 0l9-4.288C28.093 23.657 29 22.194 29 20.584v-9.168c0-1.61-.907-3.074-2.324-3.748l-9-4.288Z" stroke="url(#:R6e9l6:-gradient-dark-1)"></path>
                                        <path d="M16.406 8.087a.989.989 0 0 0-.812 0l-7 3.598A1.012 1.012 0 0 0 8 12.61v6.78c0 .4.233.762.594.925l7 3.598a.989.989 0 0 0 .812 0l7-3.598c.361-.163.594-.525.594-.925v-6.78c0-.4-.233-.762-.594-.925l-7-3.598Z" fill="url(#:R6e9l6:-gradient-dark-2)" stroke="url(#:R6e9l6:-gradient-dark-2)"></path>
                                    </g>
                                </svg>
                                <h2 class="mt-4 font-display text-base text-slate-900 "><p><span class="absolute -inset-px rounded-xl"></span>Suppliers</p></h2>
                                <p class="mt-1 text-sm text-slate-700 ">Learn how to find more Suppliers fast..</p>
                            </div>
                        </div>
                        <div class="group relative rounded-xl border border-slate-200 hover:border-2 hover:border-primary hover:bg-primary-light hover:bg-opacity-30">
                            {/* <div class="absolute -inset-px rounded-xl border-2 border-transparent opacity-0 [background:linear-gradient(var(--quick-links-hover-bg,theme(colors.sky.50)),var(--quick-links-hover-bg,theme(colors.sky.50)))_padding-box,linear-gradient(to_top,theme(colors.indigo.400),theme(colors.cyan.400),theme(colors.sky.500))_border-box] group-hover:opacity-100"></div> */}
                            <div class="relative overflow-hidden rounded-xl p-6">
                                <svg aria-hidden="true" viewBox="0 0 32 32" fill="none" class="h-8 w-8 [--icon-foreground:theme(colors.slate.900)] [--icon-background:theme(colors.white)]">
                                    <defs>
                                        <radialGradient cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" id=":R6i9l6:-gradient" gradientTransform="matrix(0 21 -21 0 12 11)">
                                            <stop stop-color="#615AA7"></stop>
                                            <stop stop-color="#615AA7" offset=".527"></stop>
                                            <stop stop-color="#998fff" offset="1"></stop>
                                        </radialGradient>
                                        <radialGradient cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" id=":R6i9l6:-gradient-dark" gradientTransform="matrix(0 24.5 -24.5 0 16 5.5)">
                                            <stop stop-color="#615AA7"></stop>
                                            <stop stop-color="#615AA7" offset=".527"></stop>
                                            <stop stop-color="#998fff" offset="1"></stop>
                                        </radialGradient>
                                    </defs>
                                    <g class="">
                                        <circle cx="12" cy="20" r="12" fill="url(#:R6i9l6:-gradient)"></circle>
                                        <path d="M27 12.13 19.87 5 13 11.87v14.26l14-14Z" class="fill-[var(--icon-background)] stroke-[color:var(--icon-foreground)]" fill-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M3 3h10v22a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V3Z" class="fill-[var(--icon-background)]" fill-opacity="0.5"></path>
                                        <path d="M3 9v16a4 4 0 0 0 4 4h2a4 4 0 0 0 4-4V9M3 9V3h10v6M3 9h10M3 15h10M3 21h10" class="stroke-[color:var(--icon-foreground)]" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M29 29V19h-8.5L13 26c0 1.5-2.5 3-5 3h21Z" fill-opacity="0.5" class="fill-[var(--icon-background)] stroke-[color:var(--icon-foreground)]" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </g>
                                    <g class="hidden">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2a1 1 0 0 0-1 1v21a6 6 0 0 0 12 0V3a1 1 0 0 0-1-1H3Zm16.752 3.293a1 1 0 0 0-1.593.244l-1.045 2A1 1 0 0 0 17 8v13a1 1 0 0 0 1.71.705l7.999-8.045a1 1 0 0 0-.002-1.412l-6.955-6.955ZM26 18a1 1 0 0 0-.707.293l-10 10A1 1 0 0 0 16 30h13a1 1 0 0 0 1-1V19a1 1 0 0 0-1-1h-3ZM5 18a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H5Zm-1-5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm1-7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H5Z" fill="url(#:R6i9l6:-gradient-dark)"></path>
                                    </g>
                                </svg>
                                <h2 class="mt-4 font-display text-base text-slate-900 "><p><span class="absolute -inset-px rounded-xl"></span>Suppliers</p></h2>
                                <p class="mt-1 text-sm text-slate-700 ">Learn how to find more Suppliers fast..</p>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <dl class="mt-12 flex border-t border-slate-200 pt-6">
                <div class="ml-auto text-right">
                    <dt class="font-display text-sm font-medium text-slate-900 ">Next</dt>
                    <dd class="mt-1">
                        <a class="text-base font-semibold text-slate-500 hover:text-slate-600  " href="/docs">
                            Welcome <span aria-hidden="true"></span>
                        </a>
                    </dd>
                </div>
            </dl>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MainSection)