import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import amer_barham from "../../assets/images/Amer_Barham.jpg";
import Products from "../../data/Products";
import ProductCard from "../../components/ProductCard";
import ZendropModal from "../../components/ZendropModal";
import { useSelector, useDispatch } from 'react-redux';
import Loader from "../../components/Loader";
import { fetchSuppliers } from "../../redux/actions/suppliersActions";
import axios from "axios";

const Supplier = () => {
  const [showImage, setShowImage] = useState(true);
  const [sidebarClosingStatus, setSidebarClosingStatus] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const products = useSelector((state) => state.supplier.products);
  const supplier = useSelector((state) => state.supplier.supplier);

  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("in use effect")
    dispatch(fetchSuppliers(params.id));
    dispatch(
      {
        type: 'LOADING',
        payload: false,
      }
    )
  }, [])

  React.useEffect(() => {
    const paragraphs = document.querySelectorAll('.fade-in');
    paragraphs.forEach((p) => {
      p.classList.add('animate-fade-in');
    });
  }, []);

  const onImageError = (e) => {
    // e.target.src = "http://localhost:8000/files/4/img6.webp";
    setShowImage(false);
  }
  return (
    <>

      <div className="flex fade-in">
        <div
          id="sidebar"
          className="invisible sm:visible transition-all duration-150 ease-in-out"
        >
          <Sidebar
            setSidebarClosingStatus={setSidebarClosingStatus}
            sidebarClosingStatus={sidebarClosingStatus}
          />
        </div>
        <div
          className={` ${sidebarClosingStatus
            ? `sm:ml-[240px] w-[80%] `
            : `sm:ml-[80px] w-[94%] `
            } px-3 py-6 flex flex-col`}
        >
          <div className="border fade-in border-gray-300 rounded-lg px-5 py-3 w-full flex space-x-1">
            <div className="w-[60%] flex flex-col space-y-2">
              <h1 className="title fade-in">{supplier?.name}</h1>
              <p className="text-gray-800">
                {" "}
                Shipping from the country: <span className="font-bold">{supplier?.country}</span>
              </p>
              <p className="text-gray-800">
                {" "}
                Average Processing time: <span className="font-bold">{supplier?.processing_time} day</span>
              </p>
              <div className="text-gray-800">
                {" "}
                <p>Description:</p>
                <p className="text-sm subtitle">
                  {supplier?.description}
                </p>
              </div>
            </div>
            <div className="w-[40%] p-3 flex justify-center">
              {showImage && <img src={supplier?.image}
                onError={onImageError}
                className="w-3/4 rounded-md" />}
                {console.log("supplier image", supplier)}
            </div>
          </div>
          <div className="flex items-center flex-wrap p-5">
            {products.map((data, i) => {
              return (
                <ProductCard data={data} key={i} />
              );
            })}
          </div>
          {showModal ? <ZendropModal setShowModal={setShowModal} /> : null}
        </div>
      </div>

    </>
  );
};

export default Supplier;
