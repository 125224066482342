import { Outlet, Navigate } from 'react-router-dom'
import { isExpired } from "react-jwt";
import { toast } from 'sonner'

const PrivateRoutes = () => {
    let auth = localStorage.getItem('accessToken')
    const isMyTokenExpired = isExpired(auth);
    let haveAccess = false;
    
    if(isMyTokenExpired) localStorage.clear();
    return(
        !isMyTokenExpired ? <Outlet/> : <Navigate to="/signin"/>
    )
}

export default PrivateRoutes