const defaultFormDetails = {
    copy_type: "Advertisement",
    product_name: "",
    product_description: "",
    brand_description: "",
    price_and_discount: "",
    product_category: "",
    product_features: "",
    product_usage: "",
    target_audience: "",
    // conclusion: "",
};

export { defaultFormDetails }