import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'


export const fetchSuppliers = (id) => {
    return async (dispatch) => {
        try {
            const api_url = `/products/supplier/${id}`;
            console.log(id)
            const { data, status } = await authenticatedInstance.get(api_url, {
                params: {id},
            });
            
            if (status === 200) {
                // Dispatch the action to update categories in the Redux store
                dispatch({
                    type: 'FETCH_SUPPLIERS',
                    payload: data,
                });
            }
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;
                if (status === 400) {
                    toast.error(data.message)
                }
            } else {
                toast.error("Suppliers could not be fetched!")
            }
        }
    };
};

export const fetchProductDetails = (productId) => async (dispatch) => {
    try {
        const { data, status } = await authenticatedInstance.get(`products/detail/${productId}`);
        if (status === 200) {
            return {
                status: true,
                data: data
            }
        }
        return {
            status: false
        }
    } catch (error) {
        console.log(error, "Product details could not be fetched");
        toast.error("Could not fetch Product details!");
        return {
            status: false
        }
    }
}


export const fetchCollectionProducts = (collection_id) => {
    return async (dispatch) => {
        try {
            const api_url = `/products/collection/${collection_id}`;
            console.log("fetchCollectionProducts")
            const { data, status } = await authenticatedInstance.get(api_url);

            console.log(data);
            if (status === 200) {
                dispatch({
                    type: 'FETCH_COLLECTION_PRODUCTS',
                    payload: data,
                });
            }
        } catch (error) {
            console.log(error, "Collection Products could not be fetched");
            toast.error("Could not fetch Collection Products!");
        }
    };
}


