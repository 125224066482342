import React from "react";
import { connect } from "react-redux";

export const Input = (props) => {
  const {
    title,
    description,
    type,
    name,
    value,
    onChange,
    placeholder,
    onBlur,
    children = null,
  } = props;

  const isMobileScreen = window.innerWidth < 768;

  return (
    <div className={`flex items-center ${isMobileScreen ? "w-full" : "w-3/4"}`}>
      <div
      style={isMobileScreen ? {
        width: "35%",
      } : {
        width: "40%",
      }}>
        <p className="text-Inter tracking-wide font-[500] text-sm leading-snug">
          {title}
          <span className="text-gray-500">
            {title === 'Price and Discount Information' && ('(optional)')}
          </span>
        </p>
        <label className="text-Inter text-gray-500 text-sm leading-snug">
          {description}
        </label>
      </div>
      <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
        {type === "textarea" ? (
          <textarea
            className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onBlur={onBlur}
            required
          />
        ) : (
          <input
            className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onBlur={onBlur}
            required
          />
        )}
      </div>
      {children}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Input);
