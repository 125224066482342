import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BackButton = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
                <button onClick={goBack} className="rounded-xl outline-none py-1 px-4 flex items-center gap-x-2">
                    {<FontAwesomeIcon icon={'arrow-left'} />}
                </button>
            </div>
        </div>


    )
}

export default BackButton