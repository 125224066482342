import React from "react";
import { Modal } from "./Modal";
import loadingAnimation from "../assets/images/Loading_Animation_Settings.gif";

const LoadingModal = () => {
  return (
    <Modal width="w-[40%]" padding="p-0">
      <div className="w-full p-8 flex flex-col space-y-2 justify-center items-center">
        <img src={loadingAnimation} className="w-24 h-24" />
        <h2 className="title">Generating the copy</h2>
        <p className="text-gray-500 text-center">
          Generating the perfect copy for your product. It might take 30 seconds
          to 1 minute
        </p>
      </div>
    </Modal>
  );
};

export default LoadingModal;
