import React, { useRef, useEffect, useState } from "react";
import Header from "./Header";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from 'sonner'
import { useSelector, useDispatch } from 'react-redux';
import { Step1, Step2 } from "../../components/AICopyCreator.js";
import { getCopyResult, createCopyEntry } from "../../redux/actions/copyAction.js";
import { connect } from 'react-redux'
import LoadingModal from "../../components/LoadingModal";
import ConfirmationModal from "../../components/ConfirmationModal";

export const CreateCopy = (props) => {
  const { getCopyResult } = props
  // const [loading, setLoading] = useState(false);
  const loading = useSelector((state) => state.copyDetails.loading);

  const [step, setStep] = useState(1);
  const [selectedType, setSelectedType] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const creditsData = useSelector((state) => state.stripe.creditsData);
  const currentPlan = useSelector((state) => state.stripe.currentPlan);

  const dispatch = useDispatch();
  const approaches = [
    {
      title: "Advertisement",
      description: "Create an ai generated advertisement for your product",
      popular: false,
    },
    {
      title: "Product Description",
      description:
        "Create an ai generated product description for your product",
      popular: true,
    },
  ];
  const globalFormDetails = useSelector((state) => state.copyDetails.formDetails);

  const [formDetails, setFormDetails] = useState({
    copy_type: approaches[selectedType].title,
    product_name: "",
    product_description: "",
    brand_description: "",
    price_and_discount: "",
    product_category: "",
    product_features: "",
    product_usage: "",
    target_audience: "",
    // conclusion: "",
  });
  const navigate = useNavigate();

  const handleNext = async () => {
    if (step === 1) {
      if (globalFormDetails?.product_name === "") {
        toast.error("Please enter product name")
      } else {
        setStep(2);
      }
    } else {
      setConfirmationModal(true);
    }
  };

  const onConfirmationHandler = async () => {
    try {
      setConfirmationModal(false);
      // setLoading(true)
      dispatch({
        type: "SET_COPY_LOADING",
        payload: true,
      });

      const chatId = await createCopyEntry({ formDetails: globalFormDetails })
      console.log(chatId, "chatId")
      if (chatId) {
        navigate(`/copy-generator/copy-result/${chatId}`);
        const res = await getCopyResult({ formDetails: globalFormDetails, chatId, currentPlan })
      }

    } finally {
      // setLoading(false)
      dispatch({
        type: "SET_COPY_LOADING",
        payload: false,
      });
    }
  }

  React.useEffect(() => {
    const paragraphs = document.querySelectorAll('.fade-in');
    paragraphs.forEach((p) => {
      p.classList.add('animate-fade-in');
    });
  }, []);

  const isMobileScreen = () => {
    return window.innerWidth < 768; // Adjust the breakpoint as needed
  }

  return (
    <div style={isMobileScreen ? {
      width: "100vw"
    } : {}}>
      {confirmationModal && ((currentPlan === 'Lifetime') ?
        <ConfirmationModal
          heading="Credit Usage Confirmation"
          message="Please Confirm to generate the copy!"
          onCancel={() => { setConfirmationModal(false) }}
          onConfirm={() => { onConfirmationHandler() }}
          loading={loading}
        /> :
        <ConfirmationModal
          heading="Credit Usage Confirmation"
          message="This will cost you 10 Credits to generate the copy!"
          onCancel={() => { setConfirmationModal(false) }}
          onConfirm={() => { onConfirmationHandler() }}
          loading={loading}
        />)
      }
      {loading && <LoadingModal />}
      <div className={`${isMobileScreen() ? "p-6" : "p-10"} fade-in`}>
        <Header step={step} selectedType={selectedType} />
        {step === 1 ? (
          <Step1
            setFormDetails={setFormDetails}
            formDetails={formDetails}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
          />
        ) : (
          <Step2
            setFormDetails={setFormDetails}
            formDetails={formDetails}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            step={step}
            setStep={setStep}
          />
        )}
        <div className={` flex items-center space-x-4 ${isMobileScreen ? "m-auto justify-end" : "w-fit ml-auto"} pr-10 my-5 pt-5`} style={(step === 1 && (isMobileScreen() == false)) ? {
          position: 'absolute',
          bottom: '1vh',
          right: '0',
          paddingBottom: '2.5rem',
        } : {}}>
          <button className="font-semibold border border-[#E4E4E7] rounded-lg px-5 py-2">
            Cancel
          </button>
          {
            loading ?
              <button
                disabled
                type="button"
                className="flex items-center space-x-2 bg-primary text-white px-5 py-2 rounded-lg"
              >
                <svg
                  role="status"
                  className="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Loading...
              </button>
              :
              <button
                className="flex items-center space-x-2 bg-primary text-white px-5 py-2 rounded-lg"
                onClick={handleNext}
              >
                <BsArrowRight />
                <p>Generate</p>
              </button>
          }
        </div>
      </div >
    </div>
  );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { getCopyResult }

export default connect(mapStateToProps, mapDispatchToProps)(CreateCopy)