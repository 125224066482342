const initialState = {
    products: [],
    supplier: {},
};

export default function suppliersReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_SUPPLIERS':
            return {
                products: action.payload.products,
                supplier: action.payload.supplier,
            };
        default:
            return state;
    }
}
