import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Listbox, Transition } from '@headlessui/react';
import { FaFilter } from "react-icons/fa6";

const FilterByDate = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialDateFilter = searchParams.get('dateFilter') || '';
    const [dateFilter, setDateFilter] = useState(initialDateFilter);

    const options = [
        { label: 'All Time', value: '' },
        { label: 'Today', value: 'today' },
        { label: '1 Week Ago', value: '1_week_ago' },
        { label: 'Last Month', value: 'last_month' },
        { label: 'Last 2 Months', value: 'last_2_months' },
        // Add more filters as needed
    ];

    // const handleChange = (selectedOption) => {
    //     console.log(selectedOption, "selected option")
    //     const updatedSearchParams = new URLSearchParams(searchParams);
    //     updatedSearchParams.set('dateFilter', selectedOption.value);
    //     setSearchParams(updatedSearchParams.toString());
    // };

    const handleChange = (selectedOption) => {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.set('dateFilter', selectedOption);
        setSearchParams(updatedSearchParams.toString());
        setDateFilter(selectedOption);
    };


    return (
        <div className="ml-6 w-36 cursor-pointer">
            <Listbox value={dateFilter} onChange={handleChange}>
                {({ open }) => (
                    <>
                        <div className="relative mt-1">
                            <Listbox.Button className="relative w-full py-2 pl-6 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-0 focus:border-gray-300 sm:text-sm flex items-center">
                                <span className="block text-center">{options.find((option) => option.value === dateFilter)?.label || 'All Time'}</span>
                                <FaFilter className="ml-4 text-gray-600" />
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={React.Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    static
                                    className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white border rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                    {options.map((option) => (
                                        <Listbox.Option
                                            key={option.value}
                                            value={option.value} // Adjusted this line
                                            className={({ active, selected }) =>
                                                `${active ? 'text-white bg-blue-600' : 'text-gray-900'}
                                                ${selected
                                                    ? 'font-semibold bg-blue-100'
                                                    : 'font-normal'
                                                }
                                                cursor-default select-none relative py-2`
                                            }
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span
                                                        className={`${selected ? 'font-semibold' : 'font-normal'
                                                            } block truncate px-6`}
                                                    >
                                                        {option.label}
                                                    </span>
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    );
};

export default FilterByDate;