const initState = {
  loading: false,
  chat_loading: false,
  formDetails: {
    copy_type: "Advertisement",
    product_name: "",
    product_description: "",
    brand_description: "",
    price_and_discount: "",
    product_category: "",
    product_features: "",
    product_usage: "",
    target_audience: "",
    // conclusion: "",
  },
  chatResult: [],
  generatedData: [],
  chatId: null,
  isStreamEnded: true,
  chatStreamEnded: true,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SCRIPT_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "CHAT_LOADING":
      return {
        ...state,
        chat_loading: !state.chat_loading,
      };
    case "RESET_CHAT_RESULTS":
      return {
        ...state,
        chatResult: [],
        chatId: null,
        isStreamEnded: true,
      };
    case "SET_FORM_DETAILS":
      return {
        ...state,
        formDetails: action.payload,
        chatResult: [],
        chatId: null
      };
    case "SET_SCRIPT_RESULT":
      return {
        ...state,
        chatResult: [...state.chatResult, action.payload?.data],
        chatId: action.payload?.id
      };
    case "UPDATE_FORM_DETAILS":
      return {
        ...state,
        formDetails: {
          ...state.formDetails,
          ...action.payload,
        },
      };
    case "CHANGE_SCRIPT_RESULT":
      return {
        ...state,
        chatResult: action.payload?.data,
        chatId: action.payload?.id
      };
    case "ADD_SCRIPT_RESULT":
      return {
        ...state,
        chatResult: [...state.chatResult, action.payload],
      };
    case "GET_GENERATED_DATA":
      return {
        ...state,
        generatedData: action.payload,
      };
    case "SET_COPY_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_STREAM_DATA":
      let chatResult = state.chatResult;
      if (chatResult.length === 0) {
        chatResult = action.payload
      }
      else {
        // chatResult.pop()
        chatResult = action.payload
      }
      return {
        ...state,
        chatResult: chatResult,
      };
    case "SET_STREAMING_ENDED":
      return {
        ...state,
        isStreamEnded: action.payload,
      };
    default:
      return state;
  }
};
