import { combineReducers } from "redux";
import auth from "./auth";
import category from "./category";
import productsReducer from "./productsReducers";
import suppliersReducer from "./suppliersReducers";
import copyDetails from "./copyDetails";
import loader from './loader';
import filters from './filter';
import editorsReducer from "./editorsChoiceReducers.js";
import stripeReducer from "./stripe";

export default combineReducers({
  auth: auth,
  category: category,
  product: productsReducer,
  supplier: suppliersReducer,
  loader: loader,
  copyDetails: copyDetails,
  filters: filters,
  editorsChoice : editorsReducer,
  stripe: stripeReducer,
});
