import React from 'react';
import { useLocation } from 'react-router-dom';

function FilterResultLine() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const range = searchParams.get('range');
    const shipFrom = searchParams.get('shipFrom');
    const shipTo = searchParams.get('shipTo');
    const shipToTime = searchParams.get('shipToTime');
    const supplier = searchParams.get('supplier');

    const filterCriteria = [];

    if (range) {
        const [min, max] = range.split('-');
        const formattedRange = max === '100' ? `$${min} to $100+` : `$${min} to $${max}`;
        filterCriteria.push(`cost: ${formattedRange}`);
    }

    if (shipFrom) {
        filterCriteria.push(`shippingFrom: "${shipFrom}"`);
    }

    if (shipTo) {
        filterCriteria.push(`shippingTo: "${shipTo}"`);
    }

    if (shipToTime) {
        filterCriteria.push(`shippingTime: "${shipToTime}"`);
    }

    if (supplier) {
        filterCriteria.push(`supplier: "${supplier}"`);
    }

    // Check if the only filter criteria is for cost (0-100), and if so, return null
    if (filterCriteria.length === 1 && filterCriteria[0].includes('cost: $0 to $100')) {
        return null;
    }

    if (filterCriteria.length === 0) {
        // No filter criteria, return null to hide the line
        return null;
    }

    return (
        <div className="bg-blue-200 p-2 mb-4">
            <p className="text-blue-800">
                Showing the result for {filterCriteria.join(', ')}
            </p>
        </div>
    );
}

export default FilterResultLine;
