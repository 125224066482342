import React, { useState } from "react";
import { connect } from "react-redux";
import { AiOutlineUser } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";

export const SelectOption = (props) => {
  const {
    title,
    description,
    name,
    value,
    onChange,
    options,
    setSelectedDropdownId,
    selectedDropdownId,
    dropdownId,
    onBlur,
  } = props;
  const [showOptions, setShowOptions] = useState(false);

  const isMobileScreen = window.innerWidth < 768;
  return (
    <div className={`flex items-center ${isMobileScreen ? "w-full" : "w-3/4"}`}>
      <div className="w-2/5">
        <p className="text-Inter tracking-wide text-sm leading-snug">{title}</p>
        <label className="text-Inter text-gray-500 text-sm leading-snug subtitle">
          {description}
        </label>
      </div>
      <div className="w-3/5 my-auto ml-auto">
        <button
          type="button"
          onClick={() => {
            setShowOptions(!showOptions);
          }}
          className="flex w-full items-center justify-between rounded-lg bg-white p-2 ring-1 ring-gray-300"
        >
          {/* <AiOutlineUser className="text-slate-400" /> */}
          <span
            className={`text-Inter text-sm font-[400] px-1 ${
              value ? `text-slate-800` : `text-slate-500`
            }`}
          >
            {value || "Select option"}
          </span>
          <BsChevronDown />
        </button>
        {showOptions && (
          <ul className="z-20 absolute mt-2 h-fit rounded bg-white ring-1 ring-gray-300 max-h-40 overflow-y-auto scroll-smooth w-[35%]">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => {
                  setShowOptions(false);
                  onChange(option, "framework");
                }}
                className="cursor-pointer text-Inter text-sm font-[400] select-none p-2 hover:bg-slate-100"
                name={name}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SelectOption);
