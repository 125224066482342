const GeneralInfo1 = [
  {
    title: "Product Description",
    description: "What is the product about?",
    placeholder: "Samsung s23 is a powerful smartphone...",
    name: "product_description",
  },
  {
    title: "Brand Description",
    description: "What is your brand about?",
    placeholder: "Samsung",
    name: "brand_description",
  },
  {
    title: "Target Audience",
    description: "Who is the product for?",
    placeholder: "Productive users",
    name: "target_audience",
  },
];
const GeneralInfo2 = [
  {
    title: "Product Features or Specifications",
    description:
      "Describe your product features or give a brief introduction about your product",
    placeholder: "8GB RAM with 128MP Camera",
    name: "product_features",
  },
  {
    title: "Target Audience",
    description: "Who is the product for?",
    placeholder: "Productive users",
    name: "target_audience",
  },
];
const backgroundInfo1 = [
  {
    title: "Price and Discount Information",
    description:
      "The product's price or discounts, and information about cost savings.",
    placeholder: "Shop now and enjoy 25% on samsung earbuds",
    name: "price_and_discount",
  },
  {
    title: "Product Category",
    description: "The category or type of the product",
    placeholder: "Electronics",
    name: "product_category",
  },
  // {
  //   title: "Conclusion",
  //   description: "What's the conclusion?",
  //   placeholder: "On the early launch days, users will get...",
  //   name: "conclusion",
  // },
];
const backgroundInfo2 = [
  {
    title: "Usage and Application",
    description: "How product can be used?",
    placeholder: "Samsung s23 ultra can be used to capture 8k videos and...",
    name: "product_usage",
  },
  {
    title: "Product Category",
    description: "The category or type of the product",
    placeholder: "Electronics",
    name: "product_category",
  },
  // {
  //   title: "Conclusion",
  //   description: "What's the conclusion?",
  //   placeholder: "Samsung s23 is a very performant and camera focused phone...",
  //   name: "conclusion",
  // },
];
export { GeneralInfo1, backgroundInfo1, GeneralInfo2, backgroundInfo2 };
