import React, { useEffect, useState } from "react";
import axios from "axios";
import { handlePayment } from "../redux/actions/paymentActions";
export default function CheckoutForm() {

    const submitHandler = async (event) => {
        event.preventDefault();
        handlePayment();
    }
    return (
        <section>
            <div className="product">
                {/* <Logo /> */}
                <div className="description">
                    <h3>Starter plan</h3>
                    <h5>$20.00 / month</h5>
                </div>
            </div>
            <form onSubmit={submitHandler} method="POST">
                {/* Add a hidden field with the lookup_key of your Price */}
                <input type="hidden" name="lookup_key" value="plan1" />
                <button id="checkout-and-portal-button" type="submit">
                    Checkout
                </button>
            </form>
        </section>
    )
}