import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { deleteCopy, getGeneratedData, fetchChat } from "../../redux/actions/copyAction";
import { defaultFormDetails } from "../../data/copyGenerator/formDetails";
import { ArrowUpDown, Menu } from 'lucide-react';
import { BiSearch } from "react-icons/bi";
import "../../styles/style.css"

function customNoDataOverlay() {
  return (
    <div className="w-full py-4 flex flex-col items-center justify-center space-y-3 h-full">
      <BiSearch className="w-10 h-10 text-gray-400" />
      <p className="text-lg font-semibold text-gray-700">
        There is no data to show
      </p>
      <p>Click on the Create New Copy button to begin your search</p>
    </div>
  );
}



export const SearchData = (props) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedCopy, setSelectedCopy] = useState(null);
  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const [openedDropdown, setOpenedDropdown] = useState(null);
  const inventoryData = props.tableData;
  const globalFormDetails = useSelector((state) => state.copyDetails.formDetails);
  const [sortedData, setSortedData] = useState(props.tableData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const paragraphs = document.querySelectorAll('.fade-in');
    paragraphs.forEach((p) => {
      p.classList.add('animate-fade-in');
    });
  }, []);

  const deleteModalHandler = (id) => {
    setSelectedCopy(id);
    setShowDeleteModal(!showDeleteModal);

    if (!id) {
      // Close the modal and set openedDropdown to null
      setShowDeleteModal(false);
      setOpenedDropdown(null);
    }
  }

  const deleteCopyHandler = async (id) => {
    // Perform the delete operation here, if needed
    dispatch(deleteCopy(id))

    // Close the modal and set openedDropdown to null
    setShowDeleteModal(false);
    setOpenedDropdown(null);
  }

  useEffect(() => {
    setLoading(true);
    console.log("getGeneratedData")
    dispatch({
      type: "SET_FORM_DETAILS",
      payload: defaultFormDetails,
    })
    dispatch(getGeneratedData());
    setLoading(false);
  }, []);

  const formatDate = (originalDate) => {
    var formattedDate = "";

    if (originalDate) {
      const currentDate = new Date();
      const dateParts = originalDate.split("T")[0].split("-");
      const [year, month, day] = dateParts.map(Number);

      if (
        year === currentDate.getFullYear() &&
        month === currentDate.getMonth() + 1 &&
        day === currentDate.getDate()
      ) {
        formattedDate = "Today";
      } else {
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        if (
          year === yesterday.getFullYear() &&
          month === yesterday.getMonth() + 1 &&
          day === yesterday.getDate()
        ) {
          formattedDate = "Yesterday";
        } else {
          formattedDate = `${day}-${month}-${year}`;
        }
      }
    }

    return formattedDate;
  };


  const toggleDropdown = (id) => {
    if (openedDropdown === id) {
      setOpenedDropdown(null);
    } else {
      setOpenedDropdown(id);
    }
  };

  const rowClickHandler = (id) => {
    dispatch(fetchChat({ chatId: id }))
    navigate(`/copy-generator/copy-result/${id}`);
  }


  const columns = [
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 2,
      minWidth: 200,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            className="flex items-center w-[100%] h-full px-3 cursor-pointer"
            onClick={() => rowClickHandler(params.row?.id)}
          >
            <div className="font-bold text-[13.5px] text-[#000000de] truncate capitalize">
              {params.row?.product_name}
            </div>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            className="flex items-center w-[100%] h-full px-3 cursor-pointer font-[400] text-[#667085] text-[13.5px] text-[#000000de] capitalize px-3"
            onClick={() => rowClickHandler(params.row?.id)}
          >
            {formatDate(params.row?.createdAt)}
          </div>
        );
      },
    },
    {
      field: " ",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="w-3/4 flex">
            <svg
              onClick={() => toggleDropdown(params.row?.id)}
              className='w-8 py-1.5 mr-7 hover:bg-slate-200 px-1.5 mt-2 rounded-lg hover:cursor-pointer'
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
            </svg>
            {openedDropdown === params.row?.id && (
              <ul className="origin-top-right absolute  ml-2 mt-10 w-32 rounded-md z-40 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className='p-1'>
                  <div className='cursor-pointer'>
                    <button
                      id={'tags'}
                      onClick={() => deleteModalHandler(params.row?.id)}
                      className="hover:bg-slate-100 hover:text-slate-900 text-slate-700 group flex rounded-md items-center w-full px-2 py-2 text-sm">
                      <svg className="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                      </svg>
                      Delete
                    </button>
                  </div>
                </div>
              </ul>
            )}
          </div>
        );
      },
    },
  ];

  const isMobileScreen = window.innerWidth < 768;
  return (
    <>
      {showDeleteModal &&
        <Modal handleClose={() => deleteModalHandler()}>
          <div className="flex flex-col items-center justify-center z-50">
            <h1 className="title">Are you sure?</h1>
            <p className="text-gray-500 text-center">
              The Copy will be deleted permanently. Click the delete button to Confirm.
            </p>
            <div className="flex items-center space-x-2 mt-5">
              <button
                className="flex items-center space-x-2 py-2 px-3 bg-white border border-gray-300 text-gray-500 rounded-xl"
                onClick={() => deleteModalHandler(null)}
              >
                <p>Cancel</p>
              </button>
              <button
                className="flex items-center space-x-2 py-2 px-3 bg-primary text-white rounded-xl"
                onClick={() => deleteCopyHandler(selectedCopy)}
              >
                <p>Delete</p>
              </button>
            </div>
          </div>
        </Modal>
      }
      <div className={`fade-in font-Inter`}>
        <div className="w-full pb-1.5 border-[1px] border-[#EAECF0]"
          style={isMobileScreen ? {
            width: "100vw",
            overflowX: "scroll",
          } : {}}>
          <div className={`${isMobileScreen ? "h-[16rem]" : "h-[42rem]"}`}
            style={isMobileScreen ? {
              width: "100vw",
            } : {}}>
            <DataGrid
              sx={{
                overflowX: "scroll",
                border: 0,
                "& .css-17jjc08-MuiDataGrid-footerContainer": {
                  borderTop: 0,
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "700",
                  fontSize: "13px",
                  lineHeight: "18px",
                  fontFamily: "Inter",
                },
                "& MuiDataGrid-columnHeaders": {
                  backgroundColor: "#F2F5F7",
                },
                "& .MuiDataGrid-iconSeparator": {
                  display: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-selectedRowCount": {
                  visibility: "hidden",
                },
                "& .css-17jjc08-MuiDataGrid-footerContainer": {
                  borderTop: 0,
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "1.15rem",
                },
                "& .MuiDataGrid-columnHeader": {
                  paddingLeft: "10px",
                  backgroundColor: "#F2F5F7",
                },
                "& .MuiDataGrid-cell": {
                  padding: "0px",
                },
                "& .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-row.Mui-selected": {
                  backgroundColor: "#F3F4F6",
                },
                "& .MuiDataGrid-row.Mui-selected:hover": {
                  backgroundColor: "#F3F4F6",
                },
              }}
              rows={inventoryData}
              columns={columns}
              loading={loading}
              getRowId={(row) => row?.id || Math.random()}
              slots={{
                noRowsOverlay: customNoDataOverlay,
                noResultsOverlay: customNoDataOverlay,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'Name', sort: 'desc' }],
                  sortModel: [{ field: 'Date', sort: 'desc' }],
                },
                pagination: { paginationModel: { pageSize: 20 } },
              }}
              pageSizeOptions={[20, 30, 50]}
            />
          </div>
        </div>
      </div>

    </>
  );
};
