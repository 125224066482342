import React from 'react'
import { connect } from 'react-redux'
import {
  GeneralInfo1,
  GeneralInfo2,
  backgroundInfo1,
  backgroundInfo2,
} from "../../data/copyGenerator/formData";
import { Input } from "../CustomInput";
import { SelectOption } from "../CustomSelection";
import { useSelector, useDispatch } from 'react-redux';

export const Step2 = (props) => {
  const dispatch = useDispatch();

  const { setFormDetails, formDetails, selectedType, setSelectedType, step, setStep } = props
  const globalFormDetails = useSelector((state) => state.copyDetails.formDetails);

  const onChange = (event) => {
    const { name, value } = event.target;
    dispatch({
      type: "SET_FORM_DETAILS",
      payload: {
        ...globalFormDetails,
        [name]: value,
      },
    })
    // setFormDetails((prev) => {
    //   return {
    //     ...prev,
    //     [name]: value,
    //   };
    // });
  };

  const framework = [
    'AIDA (Attention, Interest, Desire, Action)',
    'Problem-Agitate-Solve (PAS)',
    'Features-Advantages-Benefits (FAB)',
    'The 4 Cs (Clear, Concise, Compelling, Credible)',
    'Before-After-Bridge (BAB)',
    'The 4 Ps (Picture, Promise, Proof, Push)',
    'QUEST (Qualify, Understand, Educate, Stimulate, Transition)']


  return (
    <div>
      <div className="flex flex-col space-y-6 my-5">
        {
          formDetails.copy_type === "Advertisement" && (
            <>
              <div className="flex flex-col space-y-5">
                <h1 className="font-semibold">1. Pick a Writing Framework</h1>
                <SelectOption
                  title={"Pick Framework"}
                  description="Select Framework"
                  options={framework}
                  name="framework"
                  onChange={(e) => {
                    console.log('onChange', e)
                    dispatch({
                      type: "SET_FORM_DETAILS",
                      payload: {
                        ...globalFormDetails,
                        framework: e,
                      },
                    })
                  }
                }
                  value={framework[0]}
                />
              </div >
              <hr className="bg-gray-200 h-[1.15px] mt-1" />
            </>
          )
        }
        <div className="flex flex-col space-y-5">
          <h1 className="font-semibold">
            {globalFormDetails?.copy_type === "Advertisement" ? '2. ' : '1. '} Enter General Info About Product
          </h1>
          {selectedType === 0
            ? GeneralInfo1.map(
              ({ title, description, placeholder, name }, i) => {
                return (
                  <Input
                    title={title}
                    description={description}
                    placeholder={placeholder}
                    key={i}
                    type="text"
                    name={name}
                    onChange={onChange}
                    value={globalFormDetails[name]}
                  />
                );
              }
            )
            : GeneralInfo2.map(
              ({ title, description, placeholder, name }, i) => {
                return (
                  <Input
                    title={title}
                    description={description}
                    placeholder={placeholder}
                    key={i}
                    type="text"
                    name={name}
                    onChange={onChange}
                    value={globalFormDetails[name]}
                  />
                );
              }
            )}
        </div>
        <hr className="bg-gray-200 h-[1.15px] mt-1" />
        <div className="flex flex-col space-y-5">
          <h1 className="font-semibold">
            {globalFormDetails?.copy_type === "Advertisement" ? '3. ' : '2. '} Background About Product
          </h1>
          {selectedType === 0
            ? backgroundInfo1.map(
              ({ title, description, placeholder, name }, i) => {
                return (
                  <Input
                    title={title}
                    description={description}
                    placeholder={placeholder}
                    key={i}
                    type="textarea"
                    name={name}
                    onChange={onChange}
                    value={globalFormDetails[name]}
                  />
                );
              }
            )
            : backgroundInfo2.map(
              ({ title, description, placeholder, name }, i) => {
                return (
                  <Input
                    title={title}
                    description={description}
                    placeholder={placeholder}
                    key={i}
                    type="textarea"
                    name={name}
                    onChange={onChange}
                    value={globalFormDetails[name]}
                  />
                );
              }
            )}
        </div>
      </div >
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Step2)