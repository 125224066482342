import React from "react";
import Sidebar from "../components/Sidebar";
import { Header, LeftSideSection, MainSection, RightSideSection } from '../components/docs'
const Support = ({ sidebarClosingStatus, setSidebarClosingStatus }) => {
  return (
    <div className=''>
      {/* <Header /> */}
      <div className="flex items-center space-x-3 px-5 py-4 ">
        <p className="title">Support</p>
      </div>
      <hr className="bg-gray-200 h-[1.15px] mt-1" />
      <div className='relative mx-auto flex max-w-8xl bg-slate-50  justify-center sm:px-2 lg:px-8 xl:px-12'>
        <LeftSideSection />
        <MainSection />
        <RightSideSection />
      </div>
    </div>
  )
}

export default Support;
