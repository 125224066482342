import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';

export const Step1 = (props) => {
    const dispatch = useDispatch();
    const { setFormDetails, formDetails, selectedType, setSelectedType } = props
    const [currentDateTime, setCurrentDateTime] = useState('');
    const globalFormDetails = useSelector((state) => state.copyDetails.formDetails);

    useEffect(() => {
        const now = new Date();
        const formattedDate = format(now, "do MMMM yyyy, hh:mm a");
        setCurrentDateTime(formattedDate);
        dispatch(
            {
                type: "SET_FORM_DETAILS",
                payload: {
                    ...globalFormDetails,
                    'product_name': formattedDate,
                },
            }
        )
    }, []);


    const approaches = [
        {
            title: "Advertisement",
            description: "Create an ai generated advertisement for your product",
            popular: false,
        },
        {
            title: "Product Description",
            description:
                "Create an ai generated product description for your product",
            popular: true,
        },
    ];

    const onChange = (event) => {
        const { name, value } = event.target;
        dispatch(
            {
                type: "SET_FORM_DETAILS",
                payload: {
                    ...globalFormDetails,
                    [name]: value,
                },
            }
        )
    };

    const isMobileScreen = window.innerWidth < 768;

    return (
        <div>
            <div className="flex flex-col space-y-2 my-4">
                <p className="text-[#4E5674] font-[500] font-Inter text-sm">Product Name</p>
                <input
                    className={`bg-[#F9FAFB] rounded-xl ${isMobileScreen ? "w-9/10" : "w-1/3"} placeholder-[#6B7280] text-gray-800 outline-none py-2 px-5 border border-[#E5E7EB]`}
                    // placeholder={`${currentDateTime}`}
                    onChange={onChange}
                    name="product_name"
                    value={globalFormDetails?.product_name}
                />
            </div>
            <div className="w-full flex flex-col my-6 space-y-3"
            style={isMobileScreen ? {
                alignItems: "center",
            } : {}}>
                {approaches.map(({ title, description, popular }, i) => {
                    return (
                        <div
                            className={`flex items-center space-x-4 p-3 border rounded-lg cursor-pointer ${selectedType === i ? "border-primary" : "border-[#E4E4E7]"
                                } `}
                                style={isMobileScreen ? {
                                    width: "100%",
                                }:{
                                    width: "85%",
                                }}
                            onClick={() => {
                                setSelectedType(i);
                                dispatch(
                                    {
                                        type: "SET_FORM_DETAILS",
                                        payload: {
                                            ...globalFormDetails,
                                            'copy_type': title,
                                        },
                                    }
                                )
                            }}
                            key={i}
                        >
                            {selectedType === i ? (
                                <AiOutlineCheckCircle className="h-6 w-6 text-primary" />
                            ) : (
                                <div
                                    className={`h-6 w-6 rounded-full border border-[#E4E4E7] flex items-center justify-center`}
                                ></div>
                            )}
                            <div className="flex flex-col space-y-1">
                                <div className="text-lg flex">
                                    <p className="text-[#434b57] font-semibold">{title}</p>
                                    <span
                                        className={`${popular ? "inline" : "hidden"
                                            } bg-primary-light text-primary py-1 px-2 rounded-full text-sm ml-2`}
                                        style={{
                                            height: "fit-content",
                                        }}
                                    >
                                        Popular
                                    </span>
                                </div>
                                <p className="subtitle">{description}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Step1)