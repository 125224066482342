import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'
import { useDispatch } from "react-redux";


export const fetchCategories = () => {
  return async (dispatch) => {
    try {
      const { data, status } = await authenticatedInstance.get("/categories");
      if (status === 200) {
        // Dispatch the action to update categories in the Redux store
        dispatch({
          type: 'FETCH_CATEGORIES',
          main_categories: data.main_categories,
          sub_categories: data.sub_categories,
          collections: data.collections,
        });
      }
    } catch (error) {
      console.log(error, "Categories could not be fetched");
      toast.error("Could not fetch Categories!");
    }
  };
};

export const selectCategory = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SELECT_CATEGORY',
      payload: data,
    });
  }
}
