const initialState = {
    suppliers:[{id:0, name:'Select Supplier'}],
};

export default function filtersReducer(state = initialState, action) {
    switch (action.type) {
        // case 'SET_RANGE':
        //     return {
        //         ...state,
        //         range: action.payload,
        //     };
        // case 'SET_SHIP_FROM':
        //     return {
        //         ...state,
        //         shipFrom: action.payload,
        //     };
        // case 'SET_SHIP_TO':
        //     return {
        //         ...state,
        //         shipTo: action.payload,
        //     };
        case 'SET_SUPPLIERS':
            return {
                ...state,
                suppliers: action.payload,
            };
        default:
            return state;
    }
}
