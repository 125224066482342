import React from 'react'
import { connect } from 'react-redux'
// import logo from '../../assets/images/logo.png'
// import justDex from '../../assets/images/justDex.png'

export const Header = (props) => {
    return (
        <header class="sticky top-0 z-50 flex flex-wrap items-center justify-between bg-white px-4 py-5 shadow-md shadow-slate-900/5 transition duration-500">
            <div class="mr-6 flex lg:hidden">
                <button type="button" class="relative" aria-label="Open navigation">
                    {/* <img src={justDex} alt="Logo" /> */}
                </button>
                {/* <div style="position:fixed;top:1px;left:1px;width:1px;height:0;padding:0;margin:-1px;overflow:hidden;clip:rect(0, 0, 0, 0);white-space:nowrap;border-width:0;display:none"></div> */}
            </div>
            <div class="relative flex flex-grow basis-0 items-center">
                <a aria-label="Home page" className='cursor-pointer' href="https://app.getdex.ai/your-dex">
                    {/* <img src={logo} alt="Logo" /> */}
                </a>
            </div>
            <div class="-my-5 mr-6 sm:mr-8 md:mr-0">
                <button type="button" class="group flex h-6 w-6 items-center justify-center sm:justify-start md:h-auto md:w-80 md:flex-none md:rounded-lg md:py-2.5 md:pl-4 md:pr-3.5 md:text-sm md:ring-1 md:ring-slate-200 md:hover:ring-slate-300  lg:w-96">
                    <svg aria-hidden="true" viewBox="0 0 20 20" class="h-5 w-5 flex-none fill-slate-400 group-hover:fill-slate-500 dark:fill-slate-500 md:group-hover:fill-slate-400">
                        <path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path>
                    </svg>
                    <span class="sr-only md:not-sr-only md:ml-2 md:text-slate-500 md:dark:text-slate-400">
                        Search docs
                    </span>
                    {/* <kbd class="ml-auto hidden font-medium text-slate-400 md:block">
                        <kbd class="font-sans">Ctrl </kbd>
                        <kbd class="font-sans">K</kbd>
                    </kbd> */}
                </button>
                {/* <div style="position:fixed;top:1px;left:1px;width:1px;height:0;padding:0;margin:-1px;overflow:hidden;clip:rect(0, 0, 0, 0);white-space:nowrap;border-width:0;display:none"></div> */}
            </div>
            <div class="relative flex basis-0 justify-end gap-6 sm:gap-8 md:flex-grow"></div>
        </header>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Header)