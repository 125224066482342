const initialState = {
  loading: false,
  product_loading: false,
};

export default function loaderReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'PRODUCT_LOADING':
      console.log("PRODUCT_LOADING", action.payload)
      return {
        ...state,
        product_loading: action.payload,
      };
    default:
      return state;
  }
}
