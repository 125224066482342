import "../styles/Loader.css"

const Loader = () => {

    return (
        <div class="backdrop">
            <div class="snippet" data-title="dot-pulse">
                <div class="stage">
                    <div class="dot-pulse"></div>
                </div>
            </div>
        </div>
    )
}

export default Loader;