import React from 'react'
import { connect } from 'react-redux'

export const RightSideSection = (props) => {
    return (
        <div class="hidden xl:sticky xl:top-[4.5rem] xl:-mr-6 xl:block xl:h-[calc(100vh-4.5rem)] xl:flex-none xl:overflow-y-auto xl:py-16 xl:pr-6 px-4 ">
            <nav aria-labelledby="on-this-page-title" class="w-56">
                <h2 id="on-this-page-title" class="font-display text-sm font-medium text-slate-900 ">On this page</h2>
                <ol role="list" class="mt-4 space-y-3 text-sm">
                    <li>
                        <h3><a class="text-primary" href="docs">Quick start</a></h3>
                    </li>
                </ol>
            </nav>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RightSideSection)