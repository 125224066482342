import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'


export const fetchLists = (filters) => {
    return async (dispatch) => {
        try {
            const api_url = "/editorsChoice/lists";
            console.log("fetchLists")
            const { data, status } = await authenticatedInstance.get(api_url, {
                params: filters,
            });

            console.log(data);
            if (status === 200) {
                dispatch({
                    type: 'FETCH_LISTS',
                    payload: data,
                });
            }
        } catch (error) {
            console.log(error, "Lists could not be fetched");
            toast.error("Could not fetch Lists!");
        }
    };
};
