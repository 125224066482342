import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "../styles/style.css"

export default function Paginationbar() {
    const [searchParams, setSearchParams] = useSearchParams();
    const totalProducts = useSelector((state) => state.product.totalProducts);

    const currentPage = searchParams.get('page') || 1;
    const itemsPerPage = useSelector((state) => state.product.itemsPerPage);
    const products = useSelector((state) => state.product.products);
    const totalPages = Math.ceil(totalProducts / itemsPerPage);

    const pageSelectionHandler = (page) => {
        if (page < 1 || page > totalPages) return;
        setSearchParams({ ...searchParams, page });
    }

    const handleChange = (event, value) => {
        pageSelectionHandler(value)
    };

    return (
        <Stack spacing={2} sx={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '0 16px' }}>
            {/* <Typography>Page: {currentPage}</Typography> */}
            <Pagination count={totalPages} page={currentPage} onChange={handleChange} />
        </Stack>
    );
}
