import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchLists } from "../../redux/actions/editorsChoiceActions";
import { fetchCollectionProducts } from "../../redux/actions/productsActions";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/style.css"

const EditorsChoice = () => {
    const dispatch = useDispatch();
    const lists = useSelector((state) => state.editorsChoice.lists);
    const navigate = useNavigate();

    useEffect(() => {
        const paragraphs = document.querySelectorAll('.fade-in');
        paragraphs.forEach((p) => {
            p.classList.add('animate-fade-in');
        });
    }, []);

    const goToCollections = ({ group, collection }) => {
        // dispatch(fetchCollectionProducts(collection.id));
        navigate(`/editors-choice/${collection.name}/${collection.id}`);
    }

    const isMobileScreen = window.innerWidth < 768;

    return (
        <div style={isMobileScreen ? {
            width: "100vw"
        } : {}}>
            <div className="px-4 py-4 flex justify-between items-center fade-in font-Inter">
                <div className="flex items-center space-x-2 w-[70%] pl-5">
                    <p className="title fade-in">Editors Choice</p>
                </div>
            </div>

            <hr className="bg-gray-200 h-[1.15px] mt-1" />
            <div
                className={`items-center fade-in py-2 ${isMobileScreen ? "" : "px-5"}`}
            >

                {
                    lists.map((group, index) => (
                        group?.Collection.length > 0 &&
                        // {group.length > 0 &&
                        <div className="p-4 fade-in">
                            <div className="flex flex-col space-y-2 mb-4">
                                <h1 className="text-2xl font-Inter font-[600] text-[#101828] ease-in duration-300 fade-in">
                                    {group?.name}
                                </h1>
                                <p className="text-sm text-gray-500 leading-3 text-Inter">
                                    {group?.description}
                                </p>
                            </div>
                            {/* Collection Cards */}
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                                {group?.Collection.length === 0 ? <p className="text-gray-500 text-center">No collections to Show</p> : null}
                                {group?.Collection.map((collection, index) => (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            goToCollections({ group, collection });
                                        }}
                                        className="bg-white cursor-pointer shadow-md p-4 rounded-lg hover:shadow-lg transition duration-300"
                                        style={{
                                            background: `URL(${collection?.image[0]})`,
                                            backgroundSize: 'cover',
                                            minHeight: '120px' // Set minimum height here
                                        }}
                                    >
                                        {/* Semi-circle background */}
                                        <div className="semi-circle"></div>

                                        <h4 className="text-lg font-semibold">
                                            {/* {collection?.name} */}
                                        </h4>
                                        <p className="text-gray-600 mt-2">
                                            {/* {collection?.description} */}
                                        </p>
                                        <a className="text-blue-500 mt-4 hover:underline">
                                            {/* View Collection */}
                                        </a>
                                    </div>
                                ))}
                            </div>


                        </div>
                        // }
                    ))
                }
            </div>
        </div>
    );
}

export default EditorsChoice;