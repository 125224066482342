import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { handlePayment } from "../../redux/actions/paymentActions";
import logo from "../../assets/images/ecomLogo.png";
import { toast } from "sonner";
import { useSelector, useDispatch } from "react-redux";
import { handlePortalSession } from "../../redux/actions/paymentActions";
import { useNavigate } from "react-router-dom";

const staticPlans = [
    {
        name: 'Monthly',
        price: process.env.REACT_APP_MONTHLY_PLAN_PRICE,
        features: [
            "Unlock 1000 credits monthly",
            "Explore our Product Database",
            "Access Editors' Choice content",
            "Utilize the AI Copy Creator for your projects",
        ],
        popular: false,
        plan: 'Monthly',
    },
    {
        name: 'Annual',
        price: process.env.REACT_APP_ANNUAL_PLAN_PRICE,
        features: [
            "Enjoy 12000 credits annually",
            "Explore our Product Database",
            "Access Editors' Choice content",
            "Utilize the AI Copy Creator for your projects",
            "Benefit from unlimited support throughout the year"
        ],
        popular: true,
        plan: 'Annual',
    },
    // {
    //     name: 'Premium',
    //     price: process.env.REACT_APP_PREMIUM_PLAN_PRICE,
    //     features: [
    //         "Enjoy 12000 credits annually",
    //         "You will get 4000 CREDITS",
    //         "Access Editors' Choice content",
    //         "Utilize the AI Copy Creator for your projects",
    //     ],
    //     popular: false,
    //     plan: 'Premium',
    // },
];


const PaymentOptions = () => {
    const [loading, setLoading] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        // fetch payment options
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            toast.error("Create an Account first!");
            window.location.href = "/signup";
        }

        const subscribed = user?.subscribed;
        const subscriptionEnd = user?.subscriptionEnd;
        const dateFromString = new Date(subscriptionEnd);

        const currentDate = new Date();

        if ((dateFromString > currentDate)) {
            toast.error("You already have a plan!");
            // handlePortalSession();
            window.location.href = "/dashboard";
        }
    }, [user]);

    const buyPlan = (plan) => {
        try {

            if (user && user.paymentStatus === 'failed') {
                // dispatch(redirectCustomerPortal(user.customerId))
                return
            }

            setLoading(plan);
            dispatch(handlePayment(plan));
        } catch (err) {
            console.log("Something went wrong in buying plan");
            console.log(err);
        } 
    };

    const logoutHandler = () => {
        // localStorage.removeItem("accessToken");
        // localStorage.removeItem("refreshToken");
        // dispatch({
        //     type: "LOGOUT",
        // });

        navigate("/signup");
    }

    return (
        <>
            <div
                className={`px-5 py-3.5 flex  w-full justify-between items-center font-Inter`}
            >
                <div className="flex items-center space-x-3 w-[60%] max-w-[240px]">
                    <img src={logo} className="w-3/4 h-auto" />
                </div>
                <div>
                    <button
                        type="text"
                        className="inline-flex items-center gap-x-2 text-[14px] font-semibold font-Inter px-4 rounded-lg border-[1px] border-[#D0D5DD] py-2 shadow-leadButton hover:shadow-navButton"
                        onClick={logoutHandler}
                    >
                        <p
                            className="text-sm font-semibold text-center text-[#344054]"
                        >
                            Sign out
                        </p>
                    </button>
                </div>
            </div>
            <hr className="bg-gray-200 h-[1.15px] mt-1" />
            <div className="py-4 w-full min-h-screen relative overflow-hidden">
                <section className="text-gray-600 body-font overflow-hidden mt-5" style={
                    { marginTop: "100px" }
                }>
                    <div className="container px-8 mx-auto">
                        <div className="flex flex-wrap -m-4 justify-center">
                            {staticPlans.map((item, key) => (
                                <div key={key} className="p-4 xl:w-1/3 md:w-1/2 w-full">
                                    <div className="h-full p-6 rounded-lg border-[1px] flex flex-col relative overflow-hidden">
                                        {item.popular && (
                                            <span className="bg-primary border-primary text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                                                POPULAR
                                            </span>
                                        )}
                                        <h2 className="text-sm tracking-widest title-font mb-1 font-medium text-Inter">
                                            {item.name} Plan
                                        </h2>
                                        <h1 className="text-5xl text-gray-900 leading-none flex items-center text-Inter pb-4 mb-4 border-b border-gray-200">
                                            <span>${item.price}</span>
                                            <span className="text-lg ml-1 font-normal text-gray-500 text-Inter">/mo</span>
                                        </h1>

                                        <div className="mt-2.5 space-y-3 mb-4">
                                            {item.features.map((feature, key) => (
                                                <p key={key} className="flex items-center text-gray-600 text-Inter">
                                                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-orange-100 text-white rounded-full flex-shrink-0">
                                                        {/* Your SVG icon here */}
                                                    </span>
                                                    {feature}
                                                </p>
                                            ))}
                                        </div>

                                        {loading === item.plan ? (
                                            <button
                                                disabled
                                                type="button"
                                                className="mx-auto mt-4 text-primary text-Inter border-primary border-[1px] py-2 px-4 w-full font-semibold focus:outline-none hover:bg-priborder-primary rounded"
                                            >
                                                {/* Your loading animation here */}
                                                Loading...
                                            </button>
                                        ) : (
                                            <button
                                                disabled={loading !== null}
                                                onClick={() => buyPlan(item.plan)}
                                                // onClick={handlePortalSession}
                                                className={`mt-auto mx-auto text-primary text-Inter border-primary border-[1px] py-2 px-4 w-full font-semibold focus:outline-none hover:bg-priborder-primary rounded`}
                                            >
                                                Buy
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );

}

export default PaymentOptions;
