import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'


export const fetchProducts = (filters) => {
  console.log("product function callled")
  return async (dispatch) => {
    try {
      const api_url = "/products";
      console.log(filters)
      const { data, status } = await authenticatedInstance.get(api_url, {
        params: filters,
      });

      console.log(data, "products");
      if (status === 200) {
        await dispatch({
          type: 'TOTAL_PRODUCTS',
          payload: data?.totalProducts,
        });

        console.log(data.totalProducts, "totalProducts")
        await dispatch({
          type: 'FETCH_PRODUCTS',
          payload: data?.products,
        });

        console.log(data, "products")
      }
    } catch (error) {
      console.log(error, "Products could not be fetched");
      toast.error("Could not fetch Products!");
    }
  };
};

export const fetchProductDetails = (productId) => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get(`products/detail/${productId}`);
    if (status === 200) {
      return {
        status: true,
        data: data
      }
    }
    return {
      status: false
    }
  } catch (error) {
    console.log(error, "Product details could not be fetched");
    toast.error("Could not fetch Product details!");
    return {
      status: false
    }
  }
}


export const fetchCollectionProducts = (collection_id) => {
  return async (dispatch) => {
      try {
          const api_url = `/products/collection/${collection_id}`;
          console.log("fetchCollectionProducts")
          const { data, status } = await authenticatedInstance.get(api_url);

          console.log(data);
          if (status === 200) {
              dispatch({
                  type: 'FETCH_COLLECTION_PRODUCTS',
                  payload: data,
              });
          }
      } catch (error) {
          console.log(error, "Collection Products could not be fetched");
          toast.error("Could not fetch Collection Products!");
      }
  };
}


