import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'

export const handlePayment = async (plan) => {
    const response = await authenticatedInstance.post("/create-checkout-session", {
        // lookup_key: event.target.lookup_key.value,
        plan: plan
    });

    const url = response.data;
    window.location.href = url;
    // if (setLoading) {
    //     setLoading(null);
    // }
}


export const handleOneTimePayment = (priceId) => {
    return async (dispatch) => {

        const response = await authenticatedInstance.post("/one-time-checkout-session", {
            // lookup_key: event.target.lookup_key.value,
            priceId: priceId
        });
        console.log(response.data)
        const url = response.data;
        window.location.href = url
    }
}


export const handlePortalSession = async (setLoading) => {
    const response = await authenticatedInstance.post("/create-customer-portal-session", {
        // lookup_key: event.target.lookup_key.value,
    });
    // console.log(response.data)
    const url = response.data;
    window.open(url, "_blank");
    if (setLoading) {
        setLoading(null);
    }
}


export const creditsData = () => async (dispatch) => {
    try {
        const { data, status } = await authenticatedInstance.get('/creditsData')

        console.log(data, "data")
        if (status === 200) {
            dispatch({
                type: "UPDATE_STRIPE",
                payload: data,
            });

            dispatch({
                type: "UPDATE_CURRENT_PLAN",
                payload: data?.currentPlan,
            });

            dispatch({
                type : "SHOW_SUBSCRIBE_MODAL",
                payload : data?.showSubscriptionModal,
            })
        }
    } catch (error) {
        console.log(error, "Error in get settings")
        toast.error("Couldn't update credits")
    }
}