import React, { useState, useRef, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";

const CustomSelect = ({ value, filterData, setFilterData, data, placeholder }) => {
  const [showElements, setShowElements] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value); // Track the selected value
  const componentRef = useRef(null);

  // Handle selection of a value
  const handleSelect = (ele) => {
    if (ele === "Worldwide") ele = "";
    setSelectedValue(ele);
    setFilterData(ele);
    setShowElements(false); // Close the dropdown
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        setShowElements(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative"  ref={componentRef}>
      <div className="bg-white border-[#E5E7EB] border px-5 flex items-center space-x-2 rounded-lg text-[#6B7280] py-2">
        <AiOutlineSearch className="w-5 h-5" />
        <input
          className="w-full placeholder-[#6B7280] outline-none text-gray-800 bg-white"
          placeholder={placeholder}
          onClick={() => setShowElements(!showElements)}
          // onBlur={() => setShowElements(false)}
          value={selectedValue}
          readOnly
        />
      </div>
      {showElements ? (
        <div className="h-32 overflow-y-scroll p-3 z-20 absolute bg-gray-50 w-full flex flex-col rounded-b-lg border scroll">
          {data.map((ele, i) => {
            return (
              <p
                key={i}
                className="subtitle text-sm px-2 py-1 w-full hover:bg-gray-200/50 cursor-pointer rounded-md"
                onClick={() => {
                  console.log("Clicked"); // Add this line for debugging

                  if (typeof ele === 'object') {
                    console.log(ele.name);
                    handleSelect(ele.name);
                  } else {
                    console.log(ele);
                    handleSelect(ele);
                  }
                }}
              >
                {typeof ele === 'object' ? ele.name : ele}
              </p>
            );
          })}
        </div>
      ) : null}
    </div>

  );
};

export default CustomSelect;
