import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  AiOutlineSearch,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import CategoryCorousel from "../components/CategoryCorousel";
import Products from "../data/Products";
import ProductCard from "../components/ProductCard";
import ZendropModal from "../components/ZendropModal";
import FilterModal from "../components/FilterModal";
import axios from "axios";
import { useLocation, Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories, selectCategory } from '../redux/actions/category';
import { fetchProducts } from '../redux/actions/productsActions';
import { fetchUser } from "../redux/actions/auth";
import { handlePortalSession } from '../redux/actions/paymentActions';
import { fetchSuppliers } from "../redux/actions/filterActions";
import BackButton from "../components/BackButton";
import DataNotFound from "../components/DataNotFound";
import FilterResultLine from "../components/FilterResultLine";
import "../styles/style.css"
import LoadingSpinner from "../components/LoadingSpinner";
import { toast } from 'sonner'
import { getGeneratedData } from "../redux/actions/copyAction";
import _debounce from 'lodash/debounce';
import Paginationbar from "../components/Paginationbar";
import FilterByDate from "../components/FilterByDate";

export const Dashboard = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showFilter, setShowFilter] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const mainCategory = params.mainCategory;
  const subCategory = params.subCategory;

  const selectedCategory = useSelector((state) => state.category.selectedCategory);
  const selectedSubcategory = useSelector((state) => state.category.selectedSubcategory);
  const main_categories = useSelector((state) => state.category.main_categories);
  const sub_categories = useSelector((state) => state.category.sub_categories);
  const productsData = useSelector((state) => state.product.products);
  const itemsPerPage = useSelector((state) => state.product.itemsPerPage);
  const [products, setProducts] = useState(productsData);
  const imageUrls = products ? products.map((product) => product.image[0]) : [];
  const product_loading = useSelector((state) => state.loader.product_loading);

  // select mainCategoryiD FROM main_categories WHERE main_categories.name = mainCategory

  const mainCategoryID = main_categories.find((category) => category.name === mainCategory)?.id;
  const subCategoryID = sub_categories.find((category) => category.name === subCategory)?.id;

  const handleImageLoad = async () => {
    // Create promises for each image load event
    const loadPromises = imageUrls.map((url) => {
      
      return new Promise((resolve) => {
        const imageElement = new Image();
        imageElement.src = url;
        imageElement.onload = resolve;
        imageElement.onerror = resolve; // Resolve even if there's an error to avoid getting stuck
      });
    });

    try {
      // Wait for all image promises to resolve
      await Promise.all(loadPromises);
      console.log('All images loaded successfully');
    } catch (error) {
      console.error('Error loading images:', error);
    } finally {
      // Dispatch the action to indicate that image loading is complete
      await dispatch({
        type: 'PRODUCT_LOADING',
        payload: false,
      });
    }
  };



  useEffect(() => {
    if (searchParams.get('payment_status')) {
      if (searchParams.get('payment_status') === 'success') {
        toast.success('Payment successful')
      } else if (searchParams.get('payment_status') === 'failed') {
        toast.error('Payment Canceled!!')
      }
      searchParams.delete('payment_status');
      if (searchParams.get('session_id')) {
        searchParams.delete('session_id');
      }
      setSearchParams(searchParams);
    }

    const filters = {
      mainCategoryId: mainCategoryID,
      subcategoryId: subCategoryID,
      range: searchParams.get('range'),
      shipFrom: searchParams.get('shipFrom'),
      shipTo: searchParams.get('shipTo'),
      shipToTime: searchParams.get('shipToTime'),
      supplier: searchParams.get('supplier'),
      page: searchParams.get('page') || 1,
      itemsPerPage: itemsPerPage || 12,
      dateFilter: searchParams.get('dateFilter'),
    }

    const fetchData = async () => {
      await dispatch({
        type: 'PRODUCT_LOADING',
        payload: true,
      })

      await dispatch(fetchCategories());
      await dispatch(fetchProducts(filters));
      await dispatch(fetchSuppliers());

      handleImageLoad();
    };

    fetchData();
  }, [dispatch, searchParams, mainCategoryID, subCategoryID]);

  useEffect(() => {
    const debouncedSearch = _debounce(() => {
      if (productsData?.length === 0) {
        setProducts([])
      } else {
        const searchedData = productsData?.filter(item =>
          item.name.toLowerCase().includes(searchInput.toLowerCase())
        );
        setProducts(searchedData);
      }
    }, 300);

    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [searchInput, productsData]);


  // useEffect(() => {
  //   if (inventoryData.length === 0) {
  //     dispatch(getGeneratedData());
  //   }
  // }, [dispatch]);

  let dataFound = false;

  useEffect(() => {
    const paragraphs = document.querySelectorAll('.fade-in');
    paragraphs.forEach((p) => {
      p.classList.add('animate-fade-in');
    });
  }, []);

  const clearFilters = () => {
    const pathnameWithoutSearch = location.pathname;
    setSearchParams('');
  }

  const isMobileScreen = window.innerWidth < 768;

  return (
    <>
      {product_loading && <LoadingSpinner />}

      {
        isMobileScreen ?
          <div className="px-5 py-3.5 font-Inter">
            <div className="flex justify-between">
              <p className="title fade-in">Explore Products</p>
              <div>
                <button
                  className="bg-primary text-white rounded-xl outline-none py-2 px-4 flex items-center gap-x-2"
                  onClick={() => setShowFilter(true)}
                >
                  <BsFilter /> <p style={{ whiteSpace: 'nowrap' }}>Filter</p>
                </button>

                {searchParams.size > 0 &&
                  <button
                    className="border border-primary text-primary rounded-xl outline-none py-2 px-4 flex items-center gap-x-2 transition duration-300 ease-in-out hover:bg-[#E6F7FF]"
                    onClick={() => clearFilters()} // Implement a function to clear filters
                  >
                    <p style={{ whiteSpace: 'nowrap' }}>Clear Filters</p>
                  </button>
                }
              </div>
              {/* <div className="flex items-center space-x-2">
                <FilterByDate />
              </div> */}
            </div>
            {/* <hr className="bg-gray-200 h-[1.15px] mt-1" /> */}
            <div className={`flex justify-end mt-2`}>
              <input
                className="bg-[#F9FAFB] rounded-lg w-full placeholder-[#6B7280] outline-none py-1 px-3 border border-[#E5E7EB]"
                placeholder="Search"
                maxLength={200}
                onChange={(e) => setSearchInput(e.target.value)}
              />

            </div>
          </div>
          :
          <div className={`${isMobileScreen ? "px-2 py-2" : "px-4 py-4"} flex justify-between items-center fade-in font-Inter z-30`} style={{
            // position: 'relative',
            background: 'white',
          }}>
            <div className={`${isMobileScreen ? "pl-2" : "pl-5"} flex items-center space-x-2 w-[70%]`}>
              <p className="title fade-in">Explore Products</p>
              <div className="bg-white border-[#E5E7EB] border px-5 py-2 flex items-center space-x-2 rounded-full w-1/2 text-[#6B7280]">
                <AiOutlineSearch className="w-5 h-5" />
                <input
                  className="w-full placeholder-[#6B7280] outline-none text-gray-800 bg-white"
                  placeholder="What are you looking for?"
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>

              <button
                className="bg-primary text-white rounded-xl outline-none py-2 px-4 flex items-center gap-x-2"
                onClick={() => setShowFilter(true)}
              >
                <BsFilter /> <p style={{ whiteSpace: 'nowrap' }}>Filter</p>
              </button>
              <div className="flex items-center space-x-2">
                <FilterByDate />
              </div>
              {searchParams.size > 0 &&
                <button
                  className="border border-primary text-primary rounded-xl outline-none py-2 px-4 flex items-center gap-x-2 transition duration-300 ease-in-out hover:bg-[#E6F7FF]"
                  onClick={() => clearFilters()} // Implement a function to clear filters
                >
                  <p style={{ whiteSpace: 'nowrap' }}>Clear Filters</p>
                </button>
              }
            </div>
          </div>
      }

      <hr className="bg-gray-200 h-[1.15px] mt-1" />
      <div>
        <FilterResultLine />
        {/* <BackButton /> */}

        <CategoryCorousel sidebarClosingStatus={props.sidebarClosingStatus} />

        <div className={`flex items-center fade-in flex-wrap py-2 ${isMobileScreen ? "justify-center" : "px-5"}`}>
          {products && products
            .filter((product) => {
              if (selectedCategory) {
                if (selectedSubcategory) {
                  console.log(
                    selectedSubcategory.id + " " + product.subcategory_id
                  );
                  if (
                    product.mainCategoryId === selectedCategory.id &&
                    product.subcategory_id === selectedSubcategory.id
                  ) {
                    dataFound = true;
                    return true;
                  }
                } else if (product.mainCategoryId === selectedCategory.id) {
                  dataFound = true;
                  return true;
                }
              } else {
                dataFound = true;
                return true;
              }
              return false;
            })
            .map((data, i) => {
              return <ProductCard data={data} key={i} />;
            })}
          {!dataFound && (
            <p className="text-gray-500 font-semibold text-center my-4">
              No data found
            </p>
          )}
        </div>
        {dataFound && <Paginationbar />}
      </div>
      {showFilter ? <FilterModal setShowFilter={setShowFilter} /> : null}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
