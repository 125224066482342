import React from "react";
import "../styles/Category.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CategoryCard = ({ logo, title, mainCategory, categoryHandler, category, selected }) => {
  return (
    <>
      {
  category.name === mainCategory ? (
    <div
      onClick={() => {
        categoryHandler(category.name === mainCategory ? null : category);
      }}
      className={`capitalize flex items-center space-x-1 border rounded-xl mx-2 p-1 hover-bg-gray-100 transition-all ease-in-out duration-100 cursor-pointer ${
        category.name === mainCategory ? 'border-primary bg-primary-light' : 'border-gray-300'
      }`}
      style={{
        // minWidth: '80px',   // Set a minimum width
        // maxWidth: '300px',  // Set a maximum width
        minHeight: '42px',   // Set the minimum height, including padding
      }}
    >
      <div className={`rounded-full p-1 mx-1 ${category.name === mainCategory ? 'bg-primary-light' : ''}`}>
        {<FontAwesomeIcon icon={category.icon} />}
      </div>
      <p className={`text-gray-800 font-medium text-sm pr-2 ${category.name === mainCategory ? 'text-primary' : 'text-gray-800'}`}>
        {title}
      </p>
    </div>
  ) : (
    <div
      onClick={() => {
        categoryHandler(category.name === mainCategory ? null : category);
      }}
      className="capitalize flex items-center space-x-1 border border-gray-300 rounded-xl mx-2 p-1 hover-bg-gray-100 transition-all ease-in-out duration-100 cursor-pointer"
      style={{
        minWidth: '80px',   // Set a minimum width
        // maxWidth: '300px',  // Set a maximum width
        minHeight: '42px',   // Set the minimum height, including padding
      }}
    >
      <div className="rounded-full p-1 mx-1">{<FontAwesomeIcon icon={category.icon} />}</div>
      <p className="text-gray-800 font-medium text-sm pr-2">{title}</p>
    </div>
  )
}

    </>


  );
};

export default CategoryCard;
