import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const Header = ({ step, selectedType }) => {

  React.useEffect(() => {
    const paragraphs = document.querySelectorAll('.fade-in');
    paragraphs.forEach((p) => {
      p.classList.add('animate-fade-in');
    });
  }, []);

  return (
    <div className="flex flex-col space-y-5 pb-3 fade-in">
      <div className="flex justify-between items-center">
        <p className="subtitle">Step {step}</p>
        {/* <AiOutlineInfoCircle className="w-6 h-6 text-[#71717A]" /> */}
      </div>
      <div className="flex flex-col space-y-2">
        <h1 className="text-2xl font-Inter font-[600] text-[#101828] ease-in duration-300 fade-in">
          {step === 1
            ? "Choose Your Approach"
            : selectedType === 0
            ? "Create Advertisement with AI in Minutes"
            : "Create Product Description with AI in Minutes"}
        </h1>
        <p className="text-sm text-gray-500 leading-3 text-Inter">
          {step === 1
            ? "Choose your preferred approach to copy creation. An AI generated advertisement for your product, or an AI generated product description for your product"
            : selectedType === 0
            ? "Create amazing advertisement for your products with the help of AI. Just enter your topic, type, keywords, and tone, and let the AI do the rest. "
            : "Create amazing description for your products with the help of AI. Just enter your topic, type, keywords, and tone, and let the AI do the rest. "}
        </p>
      </div>
    </div>
  );
};

export default Header;
