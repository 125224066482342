import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Dashboard from "./Dashboard";
import Sidebar from "../components/Sidebar";
// import {  } from "react-router-dom";
import { Routes, Route, useLocation, useNavigate, Navigate } from "react-router-dom";
import Settings from "./Settings";
import Support from "./Support";
import CopyGenerator from "./Copy Generator/CopyGenerator";
import CreateCopy from "./Copy Generator/CreateCopy";
import CopyResult from "./Copy Generator/CopyResult";
import EditorsChoice from "./Lists/EditorsChoice";
import CollectionProducts from "./Lists/CollectionProducts";
import GlobalCreditModal from "../components/GlobalCreditModal";
import { useSelector } from "react-redux";
import BottomBar from "../components/BottomBar";
import Product from "./Product/Product"

export const Index = (props) => {
  const location = useLocation();
  const [sidebarClosingStatus, setSidebarClosingStatus] = useState(true);

  const { pathname } = location;
  const isDashboardPath = /^\/dashboard(\/[^/]+(\/[^/]+)?)?\/?$/.test(pathname);
  const isEditorPath = /^\/editors-choice\/[^/]+\/[^/]+$/.test(pathname);
  const isCopyGeneratorPath = /^\/copy-generator(\/[^/]+(\/[^/]+)?)?\/?$/.test(pathname);
  const isCopyResultPath = /^\/copy-generator\/copy-result\/[^/]+\/?$/.test(pathname);
  const isProductDetailPath = /^\/product-detail\/[^/]+\/?$/.test(pathname);
  const showSubscriptionModal = useSelector((state) => state.stripe.showSubscriptionModal);

  const isMobileScreen = window.innerWidth < 768;

  const navFunc = () => {
    console.log("navFunc")
    if (isMobileScreen) {
      console.log("bottom")
      return <BottomBar />
    } else {
      console.log("sidebar")
      return <div
        id="sidebar"
        className="sm:visible transition-all duration-150 ease-in-out z-10"
      >
        <Sidebar
          setSidebarClosingStatus={setSidebarClosingStatus}
          sidebarClosingStatus={sidebarClosingStatus}
        />
      </div>
    }
  }

  const navigationBar = navFunc();
console.log("navigationBar")
  return (
    
    <div className="flex">
      {navigationBar}
      <div className={`${(isMobileScreen === false) && (sidebarClosingStatus ? 'ml-[240px] w-full' : 'ml-[80px] w-full')} z-1`}>

        {isDashboardPath && (
          <Dashboard
            setSidebarClosingStatus={setSidebarClosingStatus}
            sidebarClosingStatus={sidebarClosingStatus}
          />
        )}

        {location.pathname === "/copy-generator" && (
          <CopyGenerator setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {isProductDetailPath && (
          <Product setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/copy-generator/create-copy" && (
          <CreateCopy setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/copy-generator/copy-result" && (
          <CopyResult setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {isCopyResultPath && (
          <CopyResult setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {isEditorPath && (
          <CollectionProducts setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/editors-choice" && (
          <EditorsChoice setSidebarClosingStatus={setSidebarClosingStatus} />
        )}

        {location.pathname === "/settings" && (
          <Settings setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/support" && (
          <Support sidebarClosingStatus={sidebarClosingStatus} setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {
          isMobileScreen && <div className="w-full h-[60px]"></div>
        }
        {location.pathname !== "/settings" && (
        showSubscriptionModal && <GlobalCreditModal />
      )}
      </div>
      

    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
