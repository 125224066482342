import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingModal from "../../components/LoadingModal";
import { BsRobot, BsSend } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";
import chatLoading from "../../assets/images/Loading_Animation_Chat.gif";
import { toast } from "sonner";
import { getChatResult } from "../../redux/actions/copyAction";
import { connect } from 'react-redux'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams } from "react-router-dom";
import Avatar from "../../assets/images/Avatar.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import { fetchChat } from "../../redux/actions/copyAction";

export const CopyResult = (props) => {
  const { getChatResult } = props
  const params = useParams();
  const paramValue = params['*'];

  const id = parseInt(paramValue.split('/').pop(), 10);

  const [msg, setMsg] = useState("");

  const dispatch = useDispatch();
  const targetRef = useRef(null);
  const { chatId, chatResult, formDetails, loading, chat_loading } = useSelector(
    (state) => state.copyDetails
  );
  const navigate = useNavigate();
  const goBack = () => {
    dispatch({
      type: "CHANGE_SCRIPT_RESULT",
      payload: {
        data: [],
        id: null
      },
    });
    navigate(-1);
  }

  const scrollToBottom = () => {
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {

    console.log(chatResult, "chatResult")
    if (chatResult?.length == 0) {
      dispatch(fetchChat({ chatId: id }))
    }
  }, [dispatch])

  useEffect(() => {
    const paragraphs = document.querySelectorAll('.fade-in');
    paragraphs.forEach((p) => {
      p.classList.add('animate-fade-in');
    });
  }, []);

  const sendMsg = async () => {

    setMsg("");
    scrollToBottom();
    const res = await getChatResult({ chatId, msg })
    if (res) {
      scrollToBottom();
    }
  };

  const handlePress = (event) => {
    if (event.key === "Enter") {
      sendMsg();
    }
  };

  if (loading) {
    return <LoadingModal />;
  }

  const isMobileScreen =window.innerWidth < 768; // Adjust the breakpoint as needed

  console.log(chatResult, "chatResult")
  return (
    <>
      {loading && <LoadingModal />}
      <div className="px-4 py-4 flex justify-between items-center fade-in font-Inter"
          style={isMobileScreen ? {maxWidth: '100vw'} : {}}
          >
        <div className="flex items-center space-x-2 w-[70%]">
          <button onClick={goBack} className="rounded-xl outline-none py-1 px-4 flex items-center gap-x-2">
            {<FontAwesomeIcon icon={'arrow-left'} />}
          </button>
          <p className="title fade-in capitalize" style={{
            width : "max-content",
          }}> {formDetails?.product_name} </p>

        </div>
      </div>

      <hr className="bg-gray-200 h-[1.15px] mt-1" />
      <div className="w-full relative fade-in">
        <div className={`flex flex-col overflow-y-scroll h-screen ${isMobileScreen ? "px-2 pt-8" : "px-6 pt-12"}  gap-y-3`}>

          {chatResult && (chatResult.length > 0) && chatResult.map(({ role, content, timestamp }, i) => {
            return (
              <div className={`flex ${role === "user" && "flex-row-reverse"} gap-x-2`} key={i}>
                {role === "assistant" ? (
                  <div className="flex justify-center h-fit p-3 rounded-full bg-gray-50">
                    <BsRobot className="w-7 h-7" />
                  </div>
                ) : (
                  <div className="flex justify-center h-fit p-3 rounded-full bg-gray-50">
                    <img src={Avatar} className="w-7 h-7" />
                  </div>
                )}

                <div className={`${role === "user" ? "bg-gray-100 rounded-lg" : " border border-gray-200 flex rounded-lg relative w-full"} flex p-2 justify-between gap-x-3`}>
                  {role !== "user" ? (
                    <>
                      <ReactMarkdown className="w-full">
                        {content}
                      </ReactMarkdown>

                      <CopyToClipboard
                        text={content}
                        onCopy={() => {
                          toast.success("Copied!");
                        }}
                      >
                        <FaRegCopy
                          className="w-4 h-4 text-gray-600 cursor-pointer hover:text-gray-700 ml-auto"
                        />
                      </CopyToClipboard>
                    </>
                  ) : (
                    <div className="">
                      {content.split("\n").map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                    </div>
                  )}

                  {/* Display timestamp */}
                  <p className="text-gray-600 absolute bottom-0 right-6 mb-0 mr-0" style={{
                    fontSize: "11px",
                  }}>{timestamp}</p>
                </div>
              </div>
            );
          })}

          {chat_loading ? (
            <div className="text-center w-full flex gap-x-2 items-center justify-center">
              <img src={chatLoading} className="w-10 h-auto" />
              <span className="text-gray-800">Generating Response...</span>
            </div>
          ) : null}
          <div ref={targetRef} className="mb-20 w-full"></div>
        </div>
        <div className="sticky bottom-0 px-6 py-3 w-full flex items-center bg-slate-200 gap-x-3 justify-center">
          <div className="bg-[#F9FAFB] border-[#E5E7EB] border px-5 py-2 flex items-center space-x-2 rounded-full w-[90%] text-[#6B7280]">
            <input
              className="w-full placeholder-[#6B7280] outline-none text-gray-800"
              placeholder="Say hello to the chatbot..."
              onChange={(event) => setMsg(event.target.value)}
              value={msg}
              onKeyDown={handlePress}
            />
          </div>
          <button
            className="bg-primary text-white rounded-lg outline-none py-2 px-8 flex items-center gap-x-2"
            onClick={sendMsg}
          >
            <BsSend className="text-lg" />
          </button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { getChatResult }

export default connect(mapStateToProps, mapDispatchToProps)(CopyResult)