import React from "react";
import "../styles/style.css"

const LoadingSpinner = () => {

    return (
        // <div className="flex justify-center items-center">
        //     <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        // </div>
        <div id="spinner-overlay" class="w-full h-screen z-20 overflow-hidden bg-gray-100 opacity-75 flex flex-col items-center justify-center" style={{
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: '20',
        }}>
            <div class="loader ease-linear rounded-full border-4 border-t-6 border-gray-300 h-16 w-16"></div>
            {/* <h2 class="text-center text-white text-xl font-semibold">Loading...</h2> */}
            {/* <p class="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p> */}
        </div>

    );
}

export default LoadingSpinner;