import React from 'react';
import ecomLogo from "../assets/images/ecomLogo.png";
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <div>
            <header className="border-b  border-gray-200">
                <nav className="w-9/12 m-auto py-4 flex items-center justify-between">
                    {/* Logo on the left */}
                    <div className="text-white text-2xl font-bold">
                        <img src={ecomLogo} alt="Ecom Products logo" style={{ height: '60px' }} />
                    </div>
                    {/* Navigation links on the right */}
                    <div className="flex items-center space-x-4">
                        <button className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-blue-500 hover:to-blue-500 text-white font-semibold px-6 py-3 rounded-md"
                            onClick={() => {
                                navigate('/');
                            }}
                        >
                            HOME
                        </button>
                    </div>
                </nav>
            </header>
            <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
                    <div className="border-t border-gray-200 text-center pt-8">
                        <h1 className="text-9xl font-bold text-blue-400">404</h1>
                        <h1 className="text-6xl font-medium py-8">Oops! Page not found</h1>
                        <p className="text-2xl pb-8 px-12 font-medium">
                            Oops! The page you are looking for does not exist. It might have been moved or deleted.
                        </p>
                        <button className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-blue-500 hover:to-blue-500 text-white font-semibold px-6 py-3 rounded-md"
                            onClick={() => {
                                navigate('/');
                            }}
                        >
                            HOME
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
