import React from 'react'
import { connect } from 'react-redux'

export const LeftSideSection = (props) => {
    return (
        <div class="hidden lg:relative lg:block lg:flex-none">
            <div class="absolute inset-y-0 right-0 w-[50vw]"></div>
            <div class="absolute bottom-0 right-0 top-16 hidden h-12 w-px bg-gradient-to-t from-slate-800 "></div>
            <div class="absolute bottom-0 right-0 top-28 hidden w-px bg-slate-800 "></div>
            <div class="sticky top-[4.5rem] -ml-0.5 h-[calc(100vh-4.5rem)] w-64 overflow-y-auto overflow-x-hidden py-16 pl-0.5 pr-8 xl:w-72 xl:pr-16">
                <nav class="text-base lg:text-sm">
                    <ul role="list" class="space-y-9">
                        <li>
                            <h2 class="font-display font-medium text-slate-900 ">Introduction</h2>
                            <ul role="list" class="mt-2 space-y-2 border-l-2 border-slate-100 lg:mt-4 lg:space-y-4 lg:border-slate-200">
                                <li class="relative">
                                    <a class="block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full text-slate-500 before:hidden before:bg-slate-300 hover:text-slate-600 hover:before:block" href="/docs">
                                        Welcome</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2 class="font-display font-medium text-slate-900 ">Title 1</h2>
                            <ul role="list" class="mt-2 space-y-2 border-l-2 border-slate-100 lg:mt-4 lg:space-y-4 lg:border-slate-200">
                                <li class="relative">
                                    <a class="block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full text-slate-500 before:hidden before:bg-slate-300 hover:text-slate-600 hover:before:block" href="/docs/tracking">sub title 1
                                    </a>
                                </li>
                                <li class="relative">
                                    <a class="block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full text-slate-500 before:hidden before:bg-slate-300 hover:text-slate-600 hover:before:block" href="/docs/team-sharing">sub title 2
                                    </a>
                                </li>
                                <li class="relative">
                                    <a class="block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full text-slate-500 before:hidden before:bg-slate-300 hover:text-slate-600 hover:before:block" href="/docs/personalization">sub title 3
                                    </a>
                                </li>
                            </ul>
                        </li>
                      
                    </ul>
                </nav>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideSection)