const initialState = {
    lists:[],
    collectionProducts: [],
    curr_collection: {}
};

export default function editorsReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_LISTS':
            return {
                ...state,
                lists: action.payload,
            };
        case 'FETCH_COLLECTION_PRODUCTS':
            return {
                ...state,
                collectionProducts: action.payload.products,
                curr_collection: action.payload.collection
            };
        default:
            return state;
    }
}
