import React, { useState, useEffect } from "react";
import Avatar from "../assets/images/Avatar.png";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// import 'tippy.js/dist/svg-arrow.css';

// icons
import { AiOutlineSetting, AiOutlineSearch } from "react-icons/ai";
import { BiCategoryAlt, BiSolidBadgeDollar, BiSolidMagicWand } from "react-icons/bi";
import { HiOutlineSupport, HiUserGroup } from "react-icons/hi";
import { HiOutlineChevronDoubleLeft } from "react-icons/hi";
import { AiFillHeart } from "react-icons/ai";
import { GiTakeMyMoney } from "react-icons/gi";
import { RiGraduationCapFill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails, handleSignOut } from "../redux/actions/auth";

export const SidebarRight = (props) => {

    // get current route
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentRoute = location.pathname.slice(1);

    const [selectedLink, setSelectedLink] = useState("dashboard");
    const [logoHoverStatus, setLogoHoverStatus] = useState(false);

    useEffect(() => {
        setSelectedLink(currentRoute);
    }, [currentRoute]);
    useEffect(() => {
        const paragraphs = document.querySelectorAll(".fade-in");
        paragraphs.forEach((p) => {
            p.classList.add("animate-fade-in");
        });
    }, []);


    const user = useSelector((state) => state.auth.user);

    const LowerData = [
        // {
        //   title: "Support",
        //   redirectLink: "support",
        //   selectedSvgCode: <HiOutlineSupport className="w-5 h-5 text-primary" />,
        //   unSelectedSvgCode: <HiOutlineSupport className="w-5 h-5" />,
        // },
        {
            title: "Settings",
            redirectLink: "settings",
            selectedSvgCode: <AiOutlineSetting className="w-5 h-5 text-primary" />,
            unSelectedSvgCode: <AiOutlineSetting className="w-5 h-5" />,
        },
    ];

    return (
        <div
            id="sidebar"
            className={`w-full hidden sm:block max-w-[200px]
            h-screen z-10 fixed bg-gray-800 ease-in-out duration-300 text-gray-200 right-0`}
        >
            <div className=" h-full relative ">
                <div className="absolute bottom-8 w-full">
                    <ul className="mt-4 px-3 pb-2 space-y-2.5">
                        {LowerData.map((item, index) => (
                            <li
                                key={index}
                                className={`flex items-center gap-x-3 py-1.5 rounded-lg cursor-pointer transition-all duration-200 ease-in text-gray-300 px-5  ${selectedLink.includes(item.redirectLink)
                                    ? `bg-gray-700`
                                    : `bg-gray-800 `
                                    }`}
                                onClick={() => {
                                    if (item.title === "Support") {
                                        window.open("https://help.supplierhq.io/en", "_blank");
                                        return;
                                    }
                                    navigate(`/${item.redirectLink}`);
                                    document
                                        .getElementById("sidebar")
                                        .classList.toggle("invisible");
                                }}
                            >
                                {
                                    selectedLink.includes(item.redirectLink) ? (
                                        item.selectedSvgCode
                                    ) : (
                                        item.unSelectedSvgCode
                                    )
                                }
                                {
                                    <p
                                        className={`font-Inter font-[500] ease-in duration-300 rounded-lg`}
                                    >
                                        {item.title}
                                    </p>
                                }{" "}
                            </li>
                        ))}
                    </ul>

                    {/* <hr className="bg-gray-600 py-2 mt-1 outline-none" /> */}

                    <div className="flex justify-between item-center px-3 pt-3 border-t-2 border-t-gray-700">
                        <div className="flex items-center gap-x-3 px-2 truncate mr-3">
                            {true ? (
                                <img
                                    className="h-7 ml-2"
                                    onClick={() => {
                                        navigate(`/dashboard`);
                                    }}
                                    src={Avatar}
                                />
                            ) : (
                                <div className="animate-pulse space-y-2">
                                    <div className="h-9 w-9 bg-slate-400 rounded-full"></div>
                                </div>
                            )}

                            {
                                <div className="text-gray-300">
                                    <p className="leading-5">{user?.name || "No user. Login Again"}</p>
                                    <p className="truncate text-xs leading-5">{user?.email || ""}</p>
                                </div>
                            }
                        </div>
                        {
                            <div onClick={() => {
                                dispatch({ type: "CHANGE_SIGNOUT_MODAL_STATE" })
                            }}>


                                <svg
                                    width="18"
                                    height="18"
                                    className="mt-2 cursor-pointer"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H6.5"
                                        stroke="#6B7080"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        }
                    </div>
                </div>
            </div>



        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarRight);