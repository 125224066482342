import React, { useState } from 'react';
import { handlePortalSession } from '../redux/actions/paymentActions';
import { useSelector, useDispatch } from 'react-redux';
import { handlePayment } from '../redux/actions/paymentActions';
import "../styles/style.css"

const staticPlans = [
  {
    name: 'Monthly',
    price: process.env.REACT_APP_MONTHLY_PLAN_PRICE,
    features: [
      "Unlock 1000 credits monthly",
      "Explore our Product Database",
      "Access Editors' Choice content",
      "Utilize the AI Copy Creator for your projects",
    ],
    popular: false,
    plan: 'Monthly',
  },
  {
    name: 'Annual',
    price: process.env.REACT_APP_ANNUAL_PLAN_PRICE,
    features: [
      "Enjoy 12000 credits annually",
      "Explore our Product Database",
      "Access Editors' Choice content",
      "Utilize the AI Copy Creator for your projects",
      "Benefit from unlimited support throughout the year"
    ],
    popular: true,
    plan: 'Annual',
  },
  // {
  //   name: 'Premium',
  //   price: process.env.REACT_APP_PREMIUM_PLAN_PRICE,
  //   features: [
  //     "Enjoy 12000 credits annually",
  //     "You will get 4000 CREDITS",
  //     "Access Editors' Choice content",
  //     "Utilize the AI Copy Creator for your projects",
  //   ],
  //   popular: false,
  //   plan: 'Premium',
  // },
];

const Settings = () => {
  const [loading, setLoading] = useState(null);
  const dispatch = useDispatch();
  const currentPlan = useSelector((state) => state.stripe.currentPlan);

  const user = useSelector((state) => state.auth.user);
  const handleStripe = () => {
    // Your handleStripe logic here
  };

  const buyPlan = (plan) => {
    try {

      console.log(user)
      if (user && user.paymentStatus === 'lifetime') {
        // dispatch(redirectCustomerPortal(user.customerId))
        return
      }
      
      setLoading(plan);
      handlePortalSession(setLoading);
      // dispatch(handlePayment(plan));
    } catch (err) {
      console.log("Something went wrong in buying plan");
      console.log(err);
    } 
  };

const isMobileScreen = window.innerWidth < 768;

  return (
    <div style={isMobileScreen ? {
      width: "100vw"
    } : {}}>
      <div
        className={`${isMobileScreen ? "px-7" : "px-12"} fade-in py-4 flex w-full justify-between items-center font-Inter`}
      >
        <div className="flex items-center space-x-3 w-[60%]">
          <p className="title">Payment Plans</p>
        </div>
        {/* <button
          className="bg-primary text-white rounded-xl outline-none py-2 px-4 flex items-center gap-x-2"
          onClick={() => navigate("/copy-generator/create-copy")}
        >
          <AiOutlinePlus /> <p>Create New Copy</p>
        </button> */}
      </div>
      <hr className="bg-gray-200 h-[1.15px] mt-1" />
      <div className="py-4 w-full min-h-screen overflow-hidden">
        <div className='w-full flex justify-between items-center px-8'>
          <p className="text-lg font-Inter font-[600] text-[#101828] ease-in duration-300 fade-in">
            Payment Plans
          </p>
          <button
            onClick={handleStripe}
            type="text"
            className="inline-flex items-center gap-x-2 text-[14px] font-semibold font-Inter px-4 rounded-lg border-[1px] border-[#D0D5DD] py-2 shadow-leadButton hover:shadow-navButton"
          >
            {/* Your SVG icon here */}
            <p
              onClick={handlePortalSession}
              className="text-sm font-semibold text-center text-[#344054]"
            >
              Manage subscription
            </p>
          </button>
        </div>

        <section className="text-gray-600 body-font overflow-hidden">
          <div className="container px-8 mx-auto">
            <div className="flex flex-wrap -m-4">
              {staticPlans.map((item, key) => (
                <div key={key} className="p-4 xl:w-1/4 md:w-1/2 w-full">
                  <div className="h-full p-6 relative rounded-lg border-[1px] flex flex-col overflow-hidden">
                    {item.popular && (
                      <span className="bg-primary border-primary text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                        POPULAR
                      </span>
                    )}
                    <h2 className="text-sm tracking-widest title-font mb-1 font-medium text-Inter">
                      {item.name} Plan
                    </h2>
                    <h1 className="text-5xl text-gray-900 leading-none flex items-center text-Inter pb-4 mb-4 border-b border-gray-200">
                      <span>${item.price}</span>
                      <span className="text-lg ml-1 font-normal text-gray-500 text-Inter">/mo</span>
                    </h1>

                    <div className="mt-2.5 space-y-3 mb-4">
                      {item.features.map((feature, key) => (
                        <p key={key} className="flex items-center text-gray-600 text-Inter">
                          <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-orange-100 text-white rounded-full flex-shrink-0">
                            {/* Your SVG icon here */}
                          </span>
                          {feature}
                        </p>
                      ))}
                    </div>
                    {console.log(loading, item.plan, currentPlan)}
                    {loading === item.plan ? (
                      <button
                        disabled
                        type="button"
                        className="mx-auto mt-4 text-primary text-Inter border-primary border-[1px] py-2 px-4 w-full font-semibold focus:outline-none hover:bg-priborder-primary rounded"
                      >
                        {/* Your loading animation here */}
                        Loading...
                      </button>
                    ) : currentPlan === item.plan ? (
                      <button
                        className="mt-auto font-semibold font-Inter text-sm block w-full rounded-lg transition mx-auto duration-200 ease-in bg-primary hover:bg-primary py-2.5 text-white"
                      >
                        Current Plan
                      </button>
                    ) : (
                      < button
                        disabled={loading !== null}
                    onClick={() => buyPlan(item.plan)}
                    className={`mt-auto mx-auto text-primary text-Inter border-primary border-[1px] py-2 px-4 w-full font-semibold focus:outline-none hover:bg-priborder-primary rounded`}
                      >
                    Buy
                  </button>
                    )}
                </div>
                </div>
              ))}
          </div>
      </div>
    </section >
      </div >
    </div>
  );
};

export default Settings;
