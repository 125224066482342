const initState = {
    loading: false,
    user: null,
    newUser: {},
}

export default (state = initState, action) => {
    switch (action.type) {
        case 'CHANGE_LOADING_STATE':

            return {
                ...state,
                loading: !state.loading,
            }
        case 'LOGIN':
            return {
                ...state,
                user: action.payload,
                loading: false
            }
        case 'FETCH_USER':
            return {
                ...state,
                user: action.payload.user,
                loading: false
            }
        case "SET_NEW_USER":
            return {
                ...state,
                newUser: action.payload,
            };
        case 'CHANGE_COMPANYURL':
            return {
                ...state,
                user: {
                    ...state.user,
                    companyURL: action.payload
                }
            }
        case 'LOGOUT':
            return {
                ...state,
                user: null,
                loading: false
            }
        default:
            return state;

    }
}
