import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchLists } from "../../redux/actions/editorsChoiceActions";
import { fetchCollectionProducts } from "../../redux/actions/productsActions";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import ProductCard from "../../components/ProductCard";
import BackButton from "../../components/BackButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ZendropModal from "../../components/ZendropModal";
import "../../styles/style.css"

const EditorsChoice = () => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.editorsChoice.collectionProducts);
    const collection = useSelector((state) => state.editorsChoice.curr_collection);
    const navigate = useNavigate();
    const { collection_id } = useParams();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        console.log("collection_id", collection_id)
        dispatch(fetchCollectionProducts(collection_id));
    }, [])

    useEffect(() => {
        const paragraphs = document.querySelectorAll('.fade-in');
        paragraphs.forEach((p) => {
            p.classList.add('animate-fade-in');
        });
    }, []);
    
    const goBack = () => {
        navigate(-1);
    }

    let dataFound = false;
    return (
        <>
            <div className="px-4 py-4 flex justify-between items-center fade-in font-Inter">
                <div className="flex items-center space-x-2 w-[70%]">
                    <button onClick={goBack} className="rounded-xl outline-none py-1 px-4 flex items-center gap-x-2">
                        {<FontAwesomeIcon icon={'arrow-left'} />}
                    </button>
                    <p className="title fade-in">  {collection?.name}</p>

                </div>
            </div>

            <hr className="bg-gray-200 h-[1.15px] mt-1" />
            {
                collection?.description ?
                    <div className=" mx-3 my-3 fade-in border-gray-300 rounded-lg px-5 py-3 w-full flex space-x-1">
                        <div className="w-[80%] flex flex-col space-y-2">
                            {/* <h1 className="title fade-in">{supplier?.name}</h1> */}

                            <div className="text-gray-800">
                                {/* <p>{collection?.name}:</p> */}
                                <p className="text-sm subtitle">
                                    {collection?.description}
                                </p>
                            </div>
                        </div>
                    </div> : null
            }
            {/*  */}

            <div className="flex items-center flex-wrap px-5 py-2">

                {products.map((product, i) => {
                    dataFound = true;
                    return <ProductCard
                        setShowModal={setShowModal}
                        data={product} key={i} />;
                })}

                {products.length === 0 && (
                    <p className="text-gray-500 font-semibold text-center my-4">
                        No data found
                    </p>
                )}
            </div>

            {showModal ? <ZendropModal setShowModal={setShowModal} /> : null}
        </>
    );
}

export default EditorsChoice;