import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'

export const createCopyEntry = async ({ formDetails }) => {
    try {
        const { data, status } = await authenticatedInstance.post('/api/createCopy/createCopyEntry', {
            formDetails
        })

        const chatId = data?.chatId
        if (status === 200) {
            return chatId
        }
    }
    catch (error) {
        console.log(error, "Error in creating DB Entry for Copy Generator")
        toast.error("Something went wrong while creating Entry!")
        return false
    }
}

export const getCopyResult = ({ formDetails, chatId, currentPlan }) => async (dispatch) => {
    try {
        console.log("getCopyResult")
        // dispatch({
        //     type: "SET_FORM_DETAILS",
        //     payload: formDetails,
        // });
        dispatch({
            type: "SCRIPT_LOADING",
            payload: true,
        });
        const { data, status, id } = await authenticatedInstance.post('/api/createCopy', {
            formDetails,
            chatId: chatId
        })

        console.log(data, id, "++++++++++++++")
        if (status === 200) {
            dispatch({
                type: "SCRIPT_LOADING",
                payload: false,
            });
            dispatch({
                type: "SET_SCRIPT_RESULT",
                payload: data,
            });

            console.log("getCopyResult 2", currentPlan)

            dispatch({
                type: "UPDATE_STRIPE",
                payload: {
                    credit_balance: data?.credit_balance,
                    credit_total: data?.credit_total,
                }
            })

            if (currentPlan === 'Lifetime') {
                toast.success("Copy generated successfully!")
            } else {
                toast.success(data?.credit_used + " credits have been charged for generating this copy")
            }
            return data.id
        }
    } catch (error) {
        console.log(error, "Error in get settings")
        toast.error("Something went wrong!")
        return false
    }

}

export const getGeneratedData = () => async (dispatch) => {
    try {
        const { data, status } = await authenticatedInstance.get('/api/createCopy/getGeneratedData', {

        })
        console.log("getGeneratedData 2")
        console.log(data)
        if (status === 200) {
            dispatch({
                type: "GET_GENERATED_DATA",
                payload: data,
            });

            return true
        }
    } catch (error) {
        console.log(error, "Error in get settings")
        toast.error("Something went wrong!")
        return false
    }

}

export const getChatResult = ({ chatId, msg }) => async (dispatch) => {
    try {
        dispatch({
            type: "ADD_SCRIPT_RESULT",
            payload: { role: "user", content: msg },
        });

        dispatch({
            type: "CHAT_LOADING",
            payload: true,
        });
        const { data, status } = await authenticatedInstance.post('/api/createCopy/chat', {
            message: msg,
            id: chatId
        })
        console.log("after request sent 99", data)
        if (status === 200) {
            dispatch({
                type: "CHAT_LOADING",
                payload: false,
            });
            dispatch({
                type: "SET_STREAMING_ENDED",
                payload: false,
            });

            dispatch({
                type: "ADD_SCRIPT_RESULT",
                payload: data?.data,
            });

            return true
        }
    } catch (error) {
        console.log(error, "Error in get settings")
        toast.error("Something went wrong!")
        return false
    }

}

export const fetchChat = ({ chatId }) => async (dispatch) => {
    console.log("fetchChat", chatId)
    try {
        dispatch({
            type: "CHAT_LOADING",
            payload: true,
        });
        console.log("fetchChat", chatId)
        const { data, status } = await authenticatedInstance.post('/api/createCopy/fetchChat', {
            id: chatId
        })
        console.log("after request sent", data)
        if (status === 200) {
            dispatch({
                type: "CHAT_LOADING",
                payload: false,
            });

            dispatch({
                type: "UPDATE_FORM_DETAILS",
                payload: data?.formDetails,
            })
            dispatch({
                type: "CHANGE_SCRIPT_RESULT",
                payload: data,
            });
            return true
        }
    } catch (error) {
        console.log(error, "Error in get settings")
        toast.error("Something went wrong!")
        return false
    }

}

export const deleteCopy = (id) => async (dispatch) => {
    try {
        const { data, status, deleteStatus } = await authenticatedInstance.delete('/api/createCopy/deleteCopy', {
            data: {
                id
            }
        })
        if (deleteStatus) {
            toast.success("Copy deleted successfully!")
        }
        console.log("deleted")
        if (status === 200) {
            dispatch({
                type: "GET_GENERATED_DATA",
                payload: data?.data,
            });
            return true
        }

    } catch (error) {
        console.log(error, "Error in get settings")
        toast.error("Something went wrong!")
        return false
    }

}


export const fetchLiveData = (chatId) => async (dispatch) => {
    try {
        const { data, status } = await authenticatedInstance.post('/api/createCopy/fetchLiveData', {
            id: Number(chatId)
        })

        console.log("function called 189", data.data)

        if (status === 200) {
            dispatch({
                type: "SET_STREAM_DATA",
                payload: data?.data,
            });

            dispatch({
                type: "SET_STREAMING_ENDED",
                payload: data?.isStreamEnded,
            });
        } else {
            dispatch({
                type: "SET_STREAMING_ENDED",
                payload: true,
            });
        }

        return {
            status: true,
            isStreamEnded: data?.isStreamEnded
        }

    } catch (error) {
        dispatch({
            type: "SET_STREAMING_ENDED",
            payload: true,
        });
        console.log(error, "Error in get settings")
        return {
            status: false,
            datalength: 0
        }
    }
}