import { Toaster } from "sonner";
import { useEffect } from "react";
import PrivateRoute from "./components/PrivateRoute";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Authentication from "./pages/Authentication/Authentication";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ResetPassword from "./pages/Authentication/ResetPassword";
import Index from "./pages/Index";
import Product from "./pages/Product/Product";
import Supplier from "./pages/Supplier/Supplier";
import CheckoutForm from "./pages/CheckoutForm";
import PaymentSuccess from "./pages/PaymentSuccess";
import Loader from "./components/Loader";
import PaymentStatus from "./pages/PaymentStatus";
import Settings from "./pages/Settings";
import Support from "./pages/Support";
import { useSelector, useDispatch } from 'react-redux';
import authenticatedInstance from "./redux/actions/AxiosInstance/authenticated"
import { fetchUserFirst } from "./redux/actions/auth";
import { creditsData } from "./redux/actions/paymentActions";
import { toast } from 'sonner'
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as Brands from '@fortawesome/free-brands-svg-icons';
import * as Regular from '@fortawesome/free-regular-svg-icons';
import PageNotFound from "./pages/PageNotFound";
import PaymentOptions from "./pages/PaymentOptions/PaymentOptions";
import EditorsChoice from "./pages/Lists/EditorsChoice";
import CollectionProducts from "./pages/Lists/CollectionProducts";
import PrivatePaymentRoute from "./components/PrivatePaymentRoute";

// # Free icons styles
// npm i --save @fortawesome/free-solid-svg-icons
// npm i --save @fortawesome/free-regular-svg-icons
// npm i --save @fortawesome/free-brands-svg-icons

const iconList = Object.keys(Icons)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Icons[icon]);

library.add(...iconList);

const iconList2 = Object.keys(Brands)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Brands[icon]);

library.add(...iconList2);

const iconList3 = Object.keys(Regular)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Regular[icon]);

library.add(...iconList3);

function App() {
  const { loading } = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (accessToken) {
      dispatch({ type: 'SET_LOADING', payload: true });
      dispatch(fetchUserFirst())
        .then(() => {
          dispatch(creditsData());
          dispatch({ type: 'SET_LOADING', payload: false });
        })
        .catch((error) => {
          dispatch({ type: 'SET_LOADING', payload: false });
        });
    }
  }, [accessToken, dispatch, navigate]);
  return (
    <>
      {loading && <Loader />}
      <Toaster position="top-right" richColors closeButton />
      <Routes>
        <Route path="/" element={<Navigate to="/signin" />} />
        <Route path="/signup" element={<Authentication />} />
        <Route path="/signin" element={<Authentication />} />
        <Route path="/backdoor" element={<Authentication />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/payment-options" element={<PaymentOptions />} />
        <Route path="/payment" element={<CheckoutForm />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-failed" element={<PaymentStatus />} />

        <Route path="/supplier/:id" element={<Supplier />} />
        <Route element={<PrivateRoute />} >
          <Route element={<PrivatePaymentRoute />} >
            {/* <Route path="/*" element={<Index />} /> */}
            <Route path="/dashboard" element={<Index />} />
            <Route path="/settings" element={<Index />} />
            <Route path="/copy-generator/*" element={<Index />} />
            <Route path="/dashboard/:mainCategory" element={<Index />} />
            <Route path="/product-detail/:id" element={<Index />} />
            <Route
              path="/dashboard/:mainCategory/:subCategory"
              element={<Index />}
            />
            <Route
              path="/support/"
              element={<Index />}
            />
            <Route path="/editors-choice" element={<Index />} />
            <Route path="/editors-choice/:collection/:collection_id" element={<Index />} />
          </Route>
        </Route>

        <Route path="*" element={<PageNotFound />} />
        {/* <Route path="/dashboard/*" element={<Index />} /> */}
      </Routes >
    </>
  );
}

export default App;
