import React, { useEffect } from "react";
export function Modal(props) {
  const { handleClose, type } = props;

  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  const isMobileScreen = () => {
    return window.innerWidth < 768; // Adjust the breakpoint as needed
  };

  return (
    <div
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleClose();
        }
      }}
      className={`md:fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm flex justify-center px-5 items-center w-full z-50 ${isMobileScreen() ? "fixed top-0" : ""
        }`}    >
      <div
        className={`bg-[#fff] rounded-md py-5 relative fade-in ${props.padding ? props.padding : "px-8"
          } ${isMobileScreen() ? "w-90%" : props.width ? props.width : `w-full sm:w-1/3`
          }`}
      >
        {props.children}
      </div>
    </div>

  );
}
