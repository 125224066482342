import React from "react";
import ReactSlider from "react-slider";
import "../styles/CustomSlider.css";

const CustomRange = ({range, handleRange }) => {
  const isMobileScreen = window.innerWidth < 768;
  return (
    <div className="h-12 pr-2">
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        defaultValue={range}
        // value={range}
        ariaLabel={["Lower thumb", "Upper thumb"]}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        pearling
        minDistance={10}
        onChange={(value) => handleRange(value)}
      />
    </div>
  );
};

export default CustomRange;
